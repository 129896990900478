import { atom, selector } from 'recoil';

import {
  AUTH_CONTENT,
  AUTH_MODE,
  atomKeys
} from '~/containers/ModalsManager/constants';

const readyForCallouts = atom({
  key: atomKeys.READY_FOR_CALLOUTS,
  default: false
});

const showAtcFooter = atom({
  key: atomKeys.SHOW_ATC_FOOTER,
  default: false
});

const showStickyPromo = atom({
  key: atomKeys.SHOW_STICKY_PROMO,
  default: true
});

const triedRecoveryCallout = atom({
  key: atomKeys.TRIED_RECOVERY_CALLOUT,
  default: false
});

const showAccountInfoModal = atom({
  key: atomKeys.ACCOUNT_MODAL_SHOW,
  default: false
});

const showProfileInfoModal = atom({
  key: atomKeys.PROFILE_MODAL_SHOW,
  default: false
});

const showRegisterModal = atom({
  key: atomKeys.SHOW_REGISTER_MODAL,
  default: false
});

const showResetPasswordModal = atom({
  key: atomKeys.SHOW_RESET_PASSWORD_MODAL,
  default: false
});

const authConfig = atom({
  key: atomKeys.AUTH_CONFIG,
  default: {
    authMode: AUTH_MODE?.LOGIN,
    authContent: AUTH_CONTENT?.GENERAL
  }
});

const authErrorMessage = atom({
  key: atomKeys.AUTH_ERROR_MESSAGE,
  default: ''
});

const authLocation = atom({
  key: atomKeys.AUTH_LOCATION,
  default: ''
});

const authExtraInfo = atom({
  key: atomKeys.AUTH_EXTRA_INFO,
  default: {}
});

const authConfigUpdate = selector({
  key: atomKeys.AUTH_CONFIG_UPDATE,
  get: ({ get }) => get(authConfig),
  set: ({ set }, { authMode, authContent, location, extraInfo }) => {
    set(authConfig, { authMode, authContent });
    set(authLocation, location);
    set(authExtraInfo, extraInfo);
    set(showRegisterModal, true);
  }
});

export default {
  readyForCallouts,
  showAtcFooter,
  showStickyPromo,
  triedRecoveryCallout,
  showAccountInfoModal,
  showProfileInfoModal,
  showRegisterModal,
  showResetPasswordModal,
  authConfig,
  authErrorMessage,
  authLocation,
  authExtraInfo,
  authConfigUpdate
};
