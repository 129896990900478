/* eslint-disable consistent-return */
import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { DateTime } from 'luxon';
import { useCookies } from 'react-cookie';
import { merge } from 'lodash';

import {
  contexts,
  defaultSettings
} from '~/hooks/shared/useExitIntent/utils/constants';
import isMobile from '~/hooks/shared/useExitIntent/utils/is/mobile';
import isDesktop from '~/hooks/shared/useExitIntent/utils/is/desktop';
import createDebounce from '~/hooks/shared/useExitIntent/utils/factories/debounce';
import {
  createIdleEvents,
  removeIdleEvents
} from '~/hooks/shared/useExitIntent/utils/factories/idleEvents';
import secondsToMiliseconds from '~/hooks/shared/useExitIntent/utils/secondsToMiliseconds';
import processHandlersByDeviceContext from '~/hooks/shared/useExitIntent/utils/processHandlersByDeviceContext';

export default function useExitIntent(props) {
  const initialSettings = useMemo(
    () => merge({}, defaultSettings, props),
    [props]
  );

  const [settings, setSettings] = useState(initialSettings);

  const [isTriggered, setIsTriggered] = useState(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);

  const ref = useRef([]);
  const handlers = useMemo(() => ref?.current, []);
  const shouldNotTrigger = useRef(false);

  const { mobile, desktop, cookie } = settings;
  const willBeTriggered = !(isUnsubscribed || isTriggered);

  shouldNotTrigger.current = isUnsubscribed || isTriggered;

  const [cookies, setCookie, removeCookie] = useCookies([cookie?.key]);

  const handleExitIntent = useCallback(() => {
    if (shouldNotTrigger.current) return;

    setIsTriggered(true);

    handlers
      .filter((handler) => {
        const isDefault =
          handler.context?.filter(
            (context) =>
              context !== contexts.onDesktop && context !== contexts.onMobile
          ).length === 0;

        return isDefault || handler.context?.includes(contexts.onTrigger);
      })
      .forEach(processHandlersByDeviceContext);
  }, [handlers]);

  const unsubscribe = useCallback(() => {
    setCookie(cookie.key, true, {
      expires: DateTime.now().plus({ days: cookie.daysToExpire }).toJSDate(),
      path: '/',
      sameSite: 'Strict'
    });

    handlers
      .filter((handler) => handler.context?.includes(contexts.onUnsubscribe))
      .forEach(processHandlersByDeviceContext);

    setIsUnsubscribed(true);
  }, [cookie.daysToExpire, cookie.key, handlers, setCookie]);

  const resetState = useCallback(() => {
    removeCookie(cookie?.key);
    window.onbeforeunload = null;

    setIsTriggered(false);
    setIsUnsubscribed(false);
  }, [cookie?.key, removeCookie]);

  const resetSettings = useCallback(() => {
    resetState();
    setSettings(initialSettings);
  }, [initialSettings, resetState]);

  const registerHandler = useCallback(
    (handler) => {
      const handlerAlreadyPushed = handlers.find(
        (registeredHandler) => registeredHandler.id === handler.id
      );
      const newHandler = { ...handler, context: handler?.context || [] };

      if (handlerAlreadyPushed) {
        handlers[handlers.indexOf(handlerAlreadyPushed)] = newHandler;
        return;
      }

      handlers.push(newHandler);
    },
    [handlers]
  );

  const updateSettings = useCallback(
    (_settings = defaultSettings) => {
      const newSettings = _settings;
      resetState();
      setSettings((prevSettings) => merge({}, prevSettings, newSettings));
    },
    [resetState]
  );

  useEffect(() => {
    setSettings(initialSettings);
  }, [initialSettings]);

  useEffect(() => {
    setIsUnsubscribed(cookies?.[cookie.key] === true);
  }, [cookie.key, cookies]);

  useEffect(() => {
    if (isMobile()) {
      const { execute, abort } = createDebounce(
        handleExitIntent,
        secondsToMiliseconds(mobile?.delayInSecondsToTrigger)
      );

      if (shouldNotTrigger.current) {
        removeIdleEvents(execute);
        return;
      }

      if (isMobile() && mobile?.triggerOnIdle) {
        removeIdleEvents(execute);
        createIdleEvents(execute);
      }

      return () => {
        abort();
        removeIdleEvents(execute);
      };
    }

    if (isDesktop()) {
      const { execute, abort } = createDebounce(
        handleExitIntent,
        secondsToMiliseconds(desktop?.delayInSecondsToTrigger)
      );

      if (desktop?.triggerOnIdle) {
        createIdleEvents(execute);
      }

      if (desktop?.triggerOnMouseLeave) {
        document.body.addEventListener('mouseleave', handleExitIntent);
      }

      if (desktop?.useBeforeUnload) {
        window.onbeforeunload = () => {
          if (shouldNotTrigger.current) return;
          handleExitIntent();
          return '';
        };
      }

      return () => {
        abort();
        removeIdleEvents(execute);
        document.body.removeEventListener('mouseleave', handleExitIntent);
      };
    }
  });

  return {
    settings,
    resetState,
    isTriggered,
    unsubscribe,
    resetSettings,
    updateSettings,
    isUnsubscribed,
    registerHandler,
    willBeTriggered
  };
}
