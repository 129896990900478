import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';

import {
  PROFILE_INFO_API,
  SHOPPING_SUMMARY_API
} from '~/containers/Account/constants';
import atoms from '~/containers/shared/states/atoms';
import sharedSelectors from '~/containers/shared/states/selectors';
import { sendGet, SUCCESS_CODE } from '~/utils/requestCDNApi';
import { sendGet as sendGetHV } from '~/utils/requestAPI';
import { defaultForUndefinedOrNull } from '~/utils/helper';

export default function useGetProfileInfo({
  isPrivate = false,
  skip = false
} = {}) {
  const params = useParams();
  const loggedCustomer = useRecoilValue(atoms.currentCustomer);
  const setShoppingSummary = useSetRecoilState(atoms.shoppingSummary);
  const updateReferralConfig = useSetRecoilState(
    sharedSelectors.updateReferralConfig
  );

  const authUserId = useMemo(
    () => (isPrivate ? loggedCustomer?.auth_user_id : params?.auth_user_id),
    [isPrivate, loggedCustomer, params]
  );

  const updatePrivateProfile = useSetRecoilState(
    sharedSelectors.updatePrivateProfile
  );
  const updatePublicProfile = useSetRecoilState(
    sharedSelectors.updatePublicProfile
  );

  useEffect(() => {
    const cancelSource = axios.CancelToken;
    const source = cancelSource.source();

    async function requestProfileInfo() {
      try {
        const res = await sendGet(
          PROFILE_INFO_API(authUserId),
          {},
          {
            cancelToken: source.token
          }
        );
        const { code, data = {} } = defaultForUndefinedOrNull(res.data, {});

        if (code === SUCCESS_CODE) {
          if (isPrivate) {
            updatePrivateProfile(data);
          } else {
            updatePublicProfile(data);
          }
        }
      } catch (error) {
        HV.HipvanLogger.notify(error);
      }
    }

    async function getShoppingSummary() {
      try {
        const res = await sendGetHV(
          SHOPPING_SUMMARY_API,
          {},
          {
            cancelToken: source.token
          }
        );
        const { status, data = {} } = defaultForUndefinedOrNull(res.data, {});

        if (status === SUCCESS_CODE) {
          setShoppingSummary(data);
          updateReferralConfig(data);
        }
      } catch (error) {
        HV.HipvanLogger.notify(error);
      }
    }

    if (authUserId) {
      if (isPrivate) getShoppingSummary();
      if (!skip) requestProfileInfo();
    }

    return () => {
      source.cancel('cancel initRequest effect');
    };
  }, [
    isPrivate,
    authUserId,
    updatePublicProfile,
    updatePrivateProfile,
    skip,
    setShoppingSummary,
    updateReferralConfig
  ]);
}
