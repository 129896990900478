import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import classNames from 'classnames';
import { isEmpty, noop } from 'lodash';

import atoms from '~/containers/Header/states/atoms';
import RootDropdown from '~/components/Header/NavDropdownMenu/RootDropdown';
import {
  NAV_HIERARCHY,
  NAV_MODES,
  trackNavAction
} from '~/containers/Header/analytics';
import { ga4Events } from '~/utils/analytics/gtm';
import './RootBud.scss';

const LAZY_LINK_HOVER_DELAY = 500;
const LAZY_DROPDOWN_HOVER_DELAY = 2000;
const LOCAL_HIERARCHY = NAV_HIERARCHY?.root;

export default function RootBud({
  wrapperClass = '',
  data = {},
  updateOpens = noop
}) {
  const [hoverId, setHoverId] = useRecoilState(atoms.rootHover);
  const [isLazyHover, setIsLazyHover] = useState(false);
  const {
    taxon_id: taxonId = null,
    name = null,
    relative_url: relativeUrl = null,
    categories: mainCats = [],
    menu_rich_content_cols: cmsCols = 0,
    rich_content_menu: cmsContent = null,
    only_show_menu_rich_content: cmsOnly = false
  } = data;

  // --------------------
  // Config
  // --------------------

  const linkClassnames = useMemo(() => {
    let accentClass = '';
    switch (name.toLowerCase()) {
      case 'new':
        accentClass = 'is-new';
        break;
      case 'sale':
      case 'deals':
      case 'clearance':
        accentClass = 'is-sale';
        break;
      default:
        accentClass = 'is-reg';
    }
    return [
      'c-nvRootBud__link',
      'u-inline-block',
      'u-t-nolined',
      'u-t-nooutline',
      'is-still',
      'u-t-nowrap',
      accentClass
    ].join(' ');
  }, [name]);

  const isCustomRoot = useMemo(() => globalThis?.MATTRESS_CMS_ID, []);

  const hasDropdown = useMemo(
    () =>
      mainCats.length > 0 ||
      (cmsCols > 0 && !isEmpty(cmsContent)) ||
      cmsOnly ||
      isCustomRoot,
    [cmsCols, cmsContent, cmsOnly, isCustomRoot, mainCats.length]
  );

  // --------------------
  // Hovering
  // --------------------

  const selfOnHover = useMemo(() => hoverId === taxonId, [hoverId, taxonId]);

  const toggleHover = useCallback(
    (event, id) => {
      event.stopPropagation();
      setHoverId(id);
      if (!id) setIsLazyHover(false);
    },
    [setHoverId]
  );

  useEffect(() => {
    let lazy = null;
    const delay = hasDropdown
      ? LAZY_DROPDOWN_HOVER_DELAY
      : LAZY_LINK_HOVER_DELAY;

    if (hoverId) {
      lazy = setTimeout(() => setIsLazyHover(true), delay);
    }

    return () => {
      clearTimeout(lazy);
      setIsLazyHover(false);
    };
  }, [hasDropdown, hoverId]);

  useEffect(() => {
    if (hoverId === taxonId && hasDropdown) updateOpens(name);

    return () => updateOpens(null);
  }, [hasDropdown, hoverId, name, taxonId, updateOpens]);

  // --------------------
  // Tracking
  // --------------------

  const payload = useMemo(
    () => ({
      mode: hasDropdown ? NAV_MODES?.dropdown_menu : NAV_MODES?.dropdown_link,
      path: relativeUrl,
      hierarchy: LOCAL_HIERARCHY
    }),
    [hasDropdown, relativeUrl]
  );

  const handleClick = useCallback(
    () => trackNavAction(ga4Events?.nav_click_link, payload),
    [payload]
  );

  useEffect(() => {
    if (hasDropdown && isLazyHover && selfOnHover)
      trackNavAction(ga4Events?.nav_view_menu, payload);
  }, [hasDropdown, isLazyHover, payload, selfOnHover]);

  useEffect(() => {
    if (!hasDropdown && isLazyHover && selfOnHover)
      trackNavAction(ga4Events?.nav_view_menu, payload);
  }, [hasDropdown, isLazyHover, payload, selfOnHover]);

  return (
    <li
      className={classNames('c-nvRootBud u-inline-block', {
        [wrapperClass]: !!wrapperClass,
        'is-hover': selfOnHover,
        'is-expand': selfOnHover && hasDropdown
      })}
      onMouseEnter={(event) => toggleHover(event, taxonId)}
      onMouseLeave={(event) => toggleHover(event, null)}
    >
      <a
        className={linkClassnames}
        data-nvid={taxonId}
        href={relativeUrl}
        onClick={handleClick}
        title={name}
      >
        {name}
      </a>
      <div
        className={classNames('c-nvRootBud__drop', {
          'is-active': selfOnHover && hasDropdown
        })}
      >
        <RootDropdown
          isOpen={selfOnHover && hasDropdown}
          mainCats={mainCats}
          cmsCols={cmsCols}
          cmsContent={cmsContent}
          cmsOnly={cmsOnly}
        />
      </div>
    </li>
  );
}
