import { useState, useMemo, useEffect } from 'react';
import queryString from 'query-string-for-all';
import { isEqual } from 'lodash';

import { defaultForUndefinedOrNull } from '~/utils/helper';

export default function useMutationObserverUrlData() {
  const [href, setHref] = useState(null);
  const [pathname, setPathname] = useState('');
  const [search, setSearch] = useState('');

  const queryData = useMemo(
    () =>
      queryString.parseUrl(defaultForUndefinedOrNull(href, ''), {
        parseFragmentIdentifier: true
      }),
    [href]
  );

  useEffect(() => {
    if (typeof window !== 'object') return undefined;

    const body = document.querySelector('body');
    const observer = new MutationObserver(() =>
      setHref((prev) => {
        if (isEqual(globalThis?.location?.href, prev)) return prev;

        setPathname(globalThis.location.pathname);
        setSearch(globalThis.location.search);
        return globalThis.location.href;
      })
    );
    observer.observe(body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  return { pathname, search, ...queryData };
}
