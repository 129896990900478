/* eslint-disable react/no-array-index-key */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  useRecoilValue,
  useSetRecoilState,
  useRecoilState,
  useResetRecoilState
} from 'recoil';
import { useMediaQuery } from 'react-responsive';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock-upgrade';
import classNames from 'classnames';
import { last, isEmpty } from 'lodash';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import {
  SEARCH_OVERLAY_PAGE_TITLE,
  SEARCH_OVERLAY_ROUTE
} from '~/containers/SearchResult/constants';
import {
  BURGER_TITLE,
  RECENT_SEARCH_TERMS
} from '~/containers/Header/constants';
import { mediaQueryPreset } from '~/containers/shared/constants';
import atoms from '~/containers/shared/states/atoms';
import headerAtoms from '~/containers/Header/states/atoms';
import {
  OverlayBody,
  SubSection,
  NoMatches,
  KlevuSuggestion,
  PopularSuggestion,
  RecentSearch
} from '~/components/Header/NavSearch/Overlay/components';
import NavAccordionMenu from '~/components/Header/NavAccordionMenu';
import useShoppingSearch from '~/hooks/shared/useShoppingSearch';
import useSearchSuggestions from '~/hooks/Header/useSearchSuggestions';
import { humanListCount } from '~/utils/helper';
import {
  REAL_KEY,
  sendPageViewGTM,
  sendPrevPageView
} from '~/utils/analytics/virtualPageView';
import { CG1_SEARCH } from '~/utils/analytics/shared/detectPage';
import { localStorageHelper } from '~/utils/storageHelper';
import './Overlay.scss';

export default function NavSearchOverlay({ isInline = false }) {
  const isMobile = useMediaQuery(mediaQueryPreset.mobile);
  const searchQuery = useRecoilValue(headerAtoms.navSearchQuery);
  const resetSearchQuery = useResetRecoilState(headerAtoms.navSearchQuery);

  const [isHover, setIsHover] = useState(null);

  const {
    noQuery,
    trimmedQuery,
    klevuSuggestions,
    popularSuggestions,
    recentSearches,
    suggestions
  } = useSearchSuggestions(searchQuery);

  const {
    placeholderContent,
    placeholderText,
    highlighted,
    inputOnChange,
    inputOnKeyDown,
    inputOnKeyUp,
    inputOnFocus,
    inputOnBlur,
    klevuFetching,
    matchingProductFetching,
    totalMatchingProducts,
    matchingProducts,
    searchResultPage
  } = useShoppingSearch({ trimmedQuery, suggestions });

  // --------------------
  // Dropdown Control
  // --------------------

  const fieldRef = useRef();
  const inputRef = useRef();
  const innerRef = useRef();
  const navHeight = useRecoilValue(atoms.headerHeight);
  const viewportHeight = useRecoilValue(atoms.viewportHeight);
  const setShadeOverlayOpen = useSetRecoilState(headerAtoms.shadeOverlayOpen);
  const [dropdownOpen, setDropdownOpen] = useRecoilState(
    headerAtoms.dropdownOpen
  );
  const setSearchFocus = useSetRecoilState(headerAtoms.searchFocus);
  const [dropdownHeight, setDropdownHeight] = useState(null);

  const openOverlay = useCallback(() => {
    setDropdownOpen(true);
    setShadeOverlayOpen(true);
    sendPageViewGTM({
      title: `${SEARCH_OVERLAY_PAGE_TITLE} | HipVan`,
      path: SEARCH_OVERLAY_ROUTE,
      handle: last(SEARCH_OVERLAY_ROUTE.split('/')),
      cg1: CG1_SEARCH
    });
  }, [setDropdownOpen, setShadeOverlayOpen]);

  const clearRecentSearches = useCallback(() => {
    const localRecentSearches = JSON.parse(
      localStorageHelper.getItem(RECENT_SEARCH_TERMS)
    );
    if (isEmpty(localRecentSearches))
      localStorageHelper.setItem(RECENT_SEARCH_TERMS, []);
  }, []);

  const closeOverlay = useCallback(() => {
    setShadeOverlayOpen(false);
    setDropdownOpen(false);
    sendPrevPageView(REAL_KEY);
    clearRecentSearches();
  }, [clearRecentSearches, setDropdownOpen, setShadeOverlayOpen]);

  useEffect(() => {
    if (!isMobile || !fieldRef?.current) return;
    const { bottom = 0 } = fieldRef.current.getBoundingClientRect();
    setDropdownHeight(viewportHeight - bottom);
  }, [isMobile, navHeight, viewportHeight]);

  useEffect(() => {
    if (innerRef?.current) {
      if (dropdownOpen) {
        disableBodyScroll(innerRef.current);
      } else {
        enableBodyScroll(innerRef.current);
      }
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [dropdownOpen]);

  const onScrollCb = useCallback(() => {
    if (!inputRef?.current) return;

    inputRef?.current.blur();
    setSearchFocus(false);
    setIsHover(false);
  }, [setSearchFocus]);

  return (
    <div ref={fieldRef} className="c-nvsOverlay">
      <div
        className={classNames('c-nvsOverlay__wrapper', {
          'is-focus': dropdownOpen,
          'is-hover': isHover,
          'has-q': !noQuery
        })}
      >
        <div
          className={classNames('c-nvsOverlay__group', {
            'is-search-block': !isInline
          })}
        >
          {dropdownOpen && (
            <div
              className="c-nvsOverlay__close"
              role="button"
              tabIndex={-1}
              onClick={closeOverlay}
            >
              <span
                className="c-nvsOverlay__close-ico ic-bef ic-site-arrow ic-dir-left u-p-pointer"
                aria-hidden
              />
            </div>
          )}
          <div className="c-nvsOverlay__field">
            {placeholderContent}
            <input
              ref={inputRef}
              className="c-nvsOverlay__input u-t-ellipsis u-animate-all"
              placeholder={placeholderText}
              onChange={inputOnChange}
              onKeyDown={inputOnKeyDown}
              onKeyUp={inputOnKeyUp}
              onFocus={() => inputOnFocus(openOverlay)}
              onBlur={inputOnBlur}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              value={searchQuery}
            />
            {!noQuery && (
              <span
                role="button"
                tabIndex={-1}
                className="c-nvsOverlay__reset u-t-grey u-p-pointer"
                onClick={resetSearchQuery}
              >
                <span
                  className="c-nvsOverlay__reset-icon ic-bef ic-site-cross"
                  aria-hidden
                />
              </span>
            )}
          </div>
        </div>

        {dropdownOpen && (
          <OverlayBody
            innerRef={innerRef}
            height={dropdownHeight}
            onScrollCb={onScrollCb}
          >
            {!noQuery && !matchingProductFetching && !klevuFetching ? (
              <>
                {isEmpty(klevuSuggestions) && isEmpty(matchingProducts) && (
                  <>
                    <SubSection modifier="is-nobreak">
                      <NoMatches keyword={trimmedQuery} />
                    </SubSection>
                    <SubSection heading="Popular Searches">
                      {!isEmpty(popularSuggestions) && (
                        <ul className="c-nvsOverlay__pills u-minied-list">
                          {popularSuggestions.map((item, index) => (
                            <PopularSuggestion
                              key={`${item?.id}_${index}`}
                              query={trimmedQuery}
                              item={item}
                              listPosition={humanListCount(index)}
                              totalResults={popularSuggestions?.length}
                            >
                              {highlighted(item)}
                            </PopularSuggestion>
                          ))}
                        </ul>
                      )}
                    </SubSection>
                  </>
                )}

                {!isEmpty(matchingProducts) ? (
                  <SubSection
                    heading="Matching Products"
                    total={totalMatchingProducts}
                    link={searchResultPage}
                    query={trimmedQuery}
                  >
                    <ul className="c-nvsOverlay__opts u-minied-list">
                      {matchingProducts.map((item, index) => (
                        <KlevuSuggestion
                          key={`${item?.id}_${index}`}
                          query={trimmedQuery}
                          item={item}
                          listPosition={humanListCount(index)}
                          totalResults={totalMatchingProducts}
                        >
                          {highlighted(item)}
                        </KlevuSuggestion>
                      ))}
                    </ul>
                  </SubSection>
                ) : (
                  !isEmpty(klevuSuggestions) && (
                    <SubSection modifier="is-nobreak">
                      <NoMatches keyword={trimmedQuery} />
                    </SubSection>
                  )
                )}

                {!isEmpty(klevuSuggestions) ? (
                  <SubSection
                    heading="Matching Collections"
                    total={klevuSuggestions?.length}
                  >
                    <ul className="c-nvsOverlay__opts u-minied-list">
                      {klevuSuggestions.map((item, index) => (
                        <KlevuSuggestion
                          key={`${item?.id}_${index}`}
                          query={trimmedQuery}
                          item={item}
                          listPosition={humanListCount(index)}
                          totalResults={klevuSuggestions?.length}
                          isCollection
                        >
                          {highlighted(item)}
                        </KlevuSuggestion>
                      ))}
                    </ul>
                  </SubSection>
                ) : (
                  !isEmpty(matchingProducts) && (
                    <SubSection heading={BURGER_TITLE}>
                      <NavAccordionMenu />
                    </SubSection>
                  )
                )}
              </>
            ) : (
              <>
                <SubSection heading="Recent Searches">
                  {!isEmpty(recentSearches) && isEmpty(trimmedQuery) && (
                    <ul className="c-nvsOverlay__pills u-minied-list">
                      {recentSearches.map((item, index) => (
                        <RecentSearch
                          key={item?.id}
                          item={item}
                          listPosition={humanListCount(index)}
                        >
                          {highlighted({ ...item, isRecentSearch: true })}
                        </RecentSearch>
                      ))}
                    </ul>
                  )}
                </SubSection>

                <SubSection heading="Popular Searches">
                  {!isEmpty(popularSuggestions) && (
                    <ul className="c-nvsOverlay__pills u-minied-list">
                      {popularSuggestions.map((item, index) => (
                        <PopularSuggestion
                          key={`${item?.id}_${index}`}
                          query={trimmedQuery}
                          item={item}
                          listPosition={humanListCount(index)}
                          totalResults={popularSuggestions?.length}
                        >
                          {highlighted(item)}
                        </PopularSuggestion>
                      ))}
                    </ul>
                  )}
                </SubSection>

                <SubSection heading={BURGER_TITLE}>
                  <NavAccordionMenu />
                </SubSection>
              </>
            )}
          </OverlayBody>
        )}
      </div>
    </div>
  );
}
