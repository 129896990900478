import keyMirror from 'keymirror';

const atomKeys = keyMirror({
  MOBILE_STICKY_FOOTER_HEIGHT: null,
  MOBILE_TABS_MENU_HEIGHT: null,
  AUTO_EXPAND_PRODUCT_INFO: null,
  AUTO_EXPAND_PRODUCT_REVIEWS: null,
  FLASH_POPUP: null
});

const actionKeys = keyMirror({
  PAGINATE_REVIEWS: null,
  SORT_REVIEWS: null,
  GET_BREADCRUMBS: null,
  GET_PRODUCT_INFO: null,
  GET_PRODUCT_SPECS: null,
  GET_PRODUCT_DIMENSION_IMAGES: null,
  GET_PRODUCT_VIDEO: null,
  GET_PRODUCT_RICH_DETAILS: null,
  GET_COMMUNITY_SHOWCASE: null,
  GET_PRODUCT_BUNDLE_ITEMS: null,
  GET_VISUALLY_SIMILAR_PRODUCTS: null,
  GET_COMPLEMENTARY_CATEGORY_PRODUCTS: null,
  GET_RICH_CONTENT: null,
  GET_STYLE_RECOMMENDATIONS: null,
  GET_RECENTLY_VIEWED_PRODUCTS: null,
  GET_REVIEWS: null,
  GET_REVIEW_SUMMARY: null,
  GET_REVIEW_SORT_OPTIONS: null,
  GET_VARIANTS: null,
  GET_COLOUR_GROUP_PRODUCTS: null,
  GET_DISCOUNT_CODE_ALERT: null,
  GET_BUNDLE_DISCOUNT_ALERT: null,
  SET_VARIANTS: null,
  SET_SELECTED_VARIANT: null,
  SET_SELECTED_QUANTITY: null,
  SET_USERS_HAVE_PRODUCT_IN_WISHLIST: null,
  SET_ADDED_TO_WISHLIST: null,
  SET_ADD_TO_CART_PRESENT_QTY: null,
  SET_ADD_TO_CART_VARIANT: null,
  SET_ADD_TO_CART_CHANGED_VARIANT: null,
  SET_ASSEMBLY: null,
  SET_ADD_TO_CART_ERROR: null,
  CREATE_NEW_ZENDESK_TICKET: null,
  GET_ZENDESK_DATA_SUCCESS: null,
  SET_ZENDESK_PAGE: null,
  GET_PRODUCT_QNA: null,
  UPDATE_PRODUCT_QNA: null,
  GET_PRODUCT_QNA_PARAMS: null,
  SET_PRODUCT_QNA_PAGE: null,
  RESET_PRODUCT_QNA: null,
  GET_PRODUCT_SWATCHES: null,
  SET_CART_VARIANT_REMARK: null,
  GET_COLOUR_GROUP: null
});

const communityShowcaseKeys = keyMirror({
  ACTIVE_INDEX: null,
  ACTIVE_ID: null,
  IS_OPEN_PORTAL: null,
  CLICK_ON_ITEM: null,
  WIDGET_PARAMS: null,
  SHOWCASE_DATA: null
});

const PRODUCT_DETAIL_API = '/search/products/detail';

const PRODUCT_DETAIL_EXTRA_API = '/search/products/detail_extra_info';

const PRODUCT_DETAIL_DISCOUNT_INFO_API = '/search/products/discount_info';

const DELIVERY_TIMING_API = '/delivery_schedule/delivery_timings.json';

const PRODUCT_CONTENT_TYPE = 'hv_product';

const INIT_ANCHOR_ID = 'pdtInitAnchor';
const SPECS_ANCHOR_ID = 'pdtSpecsAnchor';
const VIDEO_ANCHOR_ID = 'pdtVideoAnchor';
const REVIEWS_ANCHOR_ID = 'pdtReviewsAnchor';
const GALLERY_ANCHOR_ID = 'pdtGalleryAnchor';
const QNA_ANCHOR_ID = 'pdtQnaAnchor';
const BUNDLES_ANCHOR_ID = 'pdtBundlesAnchor';
const MORE_ANCHOR_ID = 'pdtMoreAnchor';

const COLLAPSIBLE_DESK_SPECS_KEY = 'collapsible_desk_specs';

export {
  atomKeys,
  actionKeys,
  communityShowcaseKeys,
  PRODUCT_DETAIL_API,
  PRODUCT_DETAIL_EXTRA_API,
  PRODUCT_DETAIL_DISCOUNT_INFO_API,
  DELIVERY_TIMING_API,
  PRODUCT_CONTENT_TYPE,
  INIT_ANCHOR_ID,
  SPECS_ANCHOR_ID,
  VIDEO_ANCHOR_ID,
  REVIEWS_ANCHOR_ID,
  GALLERY_ANCHOR_ID,
  QNA_ANCHOR_ID,
  BUNDLES_ANCHOR_ID,
  MORE_ANCHOR_ID,
  COLLAPSIBLE_DESK_SPECS_KEY
};
