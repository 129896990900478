/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Downshift from 'downshift';
import classNames from 'classnames';
import { findIndex } from 'lodash';

import atoms from '~/containers/Header/states/atoms';
import useShoppingSearch from '~/hooks/shared/useShoppingSearch';
import useSearchSuggestions from '~/hooks/Header/useSearchSuggestions';
import { humanListCount, defaultForUndefinedOrNull } from '~/utils/helper';
import { ga4Events } from '~/utils/analytics/gtm';
import { trackSearchSuggestAction } from '~/containers/SearchResult/analytics';
import trackKlevuSuggestionClick from '~/utils/analytics/klevu';
import './Popover.scss';

function SelectOption({
  item = {},
  itemHighlight = '',
  itemCount = 0,
  otherProps = {},
  isActive = false
}) {
  const { t } = useTranslation('products');

  return (
    <li
      {...otherProps}
      className={classNames('c-nvsPop__item', {
        'is-active': isActive
      })}
    >
      <a
        className="c-nvsPop__link u-block u-t-body"
        href={item?.formatted_url}
        title={item?.name}
      >
        <div className="c-nvsPop__link-wrap">
          <div className="c-nvsPop__link-label">{itemHighlight}</div>
          {!!itemCount && (
            <div className="c-nvsPop__link-count u-t-greymid u-t-smaller">{`${itemCount} ${t(
              'products:product.unit',
              {
                count: itemCount
              }
            )}`}</div>
          )}
        </div>
      </a>
    </li>
  );
}

export default function NavSearchPopover() {
  const [isHover, setIsHover] = useState(null);
  const searchFocus = useRecoilValue(atoms.searchFocus);
  const searchQuery = useRecoilValue(atoms.navSearchQuery);

  const stateReducer = useCallback((state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.changeInput:
        return {
          ...changes,
          isOpen: state.isOpen
        };
      default:
        return changes;
    }
  }, []);

  const { trimmedQuery, searchHrefFor, suggestions } =
    useSearchSuggestions(searchQuery);

  const {
    placeholderContent,
    placeholderText,
    highlighted,
    inputOnChange,
    inputOnKeyDown,
    inputOnKeyUp,
    inputOnFocus,
    inputOnBlur,
    trackIntent
  } = useShoppingSearch({ trimmedQuery, suggestions });

  const itemToString = useCallback(
    (item) => (item?.id ? '' : trimmedQuery),
    [trimmedQuery]
  );

  const onSelect = useCallback(
    (selectedItem) => {
      const optionHref = searchHrefFor(
        selectedItem?.search_type,
        selectedItem?.name,
        selectedItem?.url
      );

      trackSearchSuggestAction(ga4Events?.click_search_suggest, {
        query: trimmedQuery,
        total: defaultForUndefinedOrNull(suggestions?.length, 0),
        title: selectedItem?.name,
        url: optionHref,
        listPosition: humanListCount(
          findIndex(suggestions, (r) => r.id === selectedItem?.id)
        ),
        mode: selectedItem?.search_type,
        trigger: 'popular'
      });

      if (selectedItem?.url)
        trackKlevuSuggestionClick({
          keywords: trimmedQuery,
          name: selectedItem?.name,
          id: selectedItem?.id,
          groupId: selectedItem?.id,
          variantId: selectedItem?.id,
          url: selectedItem?.url,
          type: 'clicked'
        });

      trackIntent(() => {
        globalThis.location.href = optionHref;
      });
    },
    [trimmedQuery, searchHrefFor, suggestions, trackIntent]
  );

  return (
    <Downshift
      stateReducer={stateReducer}
      itemToString={itemToString}
      onSelect={onSelect}
    >
      {({
        getRootProps,
        getLabelProps,
        getInputProps,
        getMenuProps,
        getItemProps,
        highlightedIndex,
        isOpen,
        openMenu
      }) => (
        <div className="c-nvsPop">
          <div
            className={classNames('c-nvsPop__wrapper', {
              'is-focus': isOpen,
              'is-hover': isHover
            })}
          >
            <div
              className="c-nvsPop__field"
              {...getRootProps({}, { suppressRefError: true })}
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="c-nvsPop__label hidden" {...getLabelProps()}>
                Search
              </label>
              {placeholderContent}
              <input
                className="c-nvsPop__input u-animate-all"
                {...getInputProps({
                  placeholder: placeholderText,
                  value: searchQuery,
                  onChange: inputOnChange,
                  onKeyDown: inputOnKeyDown,
                  onKeyUp: inputOnKeyUp,
                  onFocus: () => inputOnFocus(openMenu),
                  onBlur: inputOnBlur,
                  onMouseEnter: () => setIsHover(true),
                  onMouseLeave: () => setIsHover(false)
                })}
              />
            </div>
            {searchFocus && (
              <div className="c-nvsPop__drop u-animate-all">
                <ul
                  className="c-nvsPop__list u-minied-list"
                  {...getMenuProps()}
                >
                  {suggestions.map((item, index) => (
                    <SelectOption
                      key={`${item?.id}_${index}`}
                      item={item}
                      itemHighlight={highlighted(item)}
                      itemCount={item?.productCount}
                      otherProps={getItemProps({
                        key: `${item?.id}_${index}`,
                        index,
                        item
                      })}
                      isActive={highlightedIndex === index}
                    />
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </Downshift>
  );
}
