import keyMirror from 'keymirror';

const atomKeys = keyMirror({
  TAXON: null,
  PRODUCTS: null,
  CATEGORIES: null,
  PAGINATION: null,
  BREADCRUMBS: null,
  AGGREGATIONS: null,
  SORT_OPTIONS: null,
  QUERY_PARAMS: null,
  RELATED_TAXON: null,
  BESTSELLER_IDS: null,
  CMS_RICH_CONTENTS: null,
  CATEGORY_CHILDREN: null,
  LOADING_MORE_PRODUCT: null,
  BUNDLE_DISCOUNT_ALERT: null,
  RELATED_TAXON_PRODUCTS: null,
  PRODUCT_STATS_IN_TAXON: null,
  NEED_LOAD_MORE_PRODUCT: null,
  BEST_FITTED_WIDTH_PRODUCT: null,
  COMPLEMENTARY_CATEGORIES: null,
  FORCE_PAGE: null,
  SELECTED_FILTERED: null,
  SELECTED_FILTERED_COUNT: null
});

const selectorKeys = keyMirror({
  AXIS_TICKS: null,
  APP_UPDATE: null,
  SORT_QUERY: null,
  PRICE_QUERY: null,
  RESET_PRICE_QUERY: null,
  WIDTH_QUERY: null,
  RESET_WIDTH_QUERY: null,
  COLOUR_QUERY: null,
  RESET_COLOUR_QUERY: null,
  STATES_RESET: null,
  APP_INITIALIZE: null,
  APP_EXTRA: null,
  PRODUCT_UPDATE: null,
  FIRMNESS_QUERY: null,
  RESET_FIRMNESS_QUERY: null,
  PRODUCTS_QUERY: null,
  PAGINATION_QUERY: null,
  OTHER_CATEGORIES: null,
  AGGREGATIONS_OPS: null,
  SELECTED_SORT_OPS: null,
  PRESENTED_SORT_OPTS: null,
  IS_SHOWED_WIDTH_SORT: null,
  QUERY_PARAMS_DEFAULT: null,
  DIMENSIONS_FILTER_WIDTH: null,
  SHIPPING_DURATION_QUERY: null,
  RESET_SHIPPING_DURATION_QUERY: null,
  BEST_FITTED_WIDTH_PRODUCT_UPDATE: null
});

const CATEGORY_FILTER_LINK_REF = 'coll_filter';

const COLLECTION_API = '/search/collections';

const COLLECTION_EXTRA_API = `${COLLECTION_API}/extra_info`;

const COLLECTION_SUPPORTED_FILTER_API = `${COLLECTION_API}/supported_filter`;

const PRODUCT_PREVIEW_API = '/search/products/quick_view';

const FILTER_SHIPPING_KEY = 'estimated_delivery';

const FILTER_PRICE_KEY = 'price';

const FILTER_COLOUR_KEY = 'colour';

const FILTER_PRICE_RANGE_KEY = 'price_range';

const FILTER_PRICE_MAX_KEY = 'max_price';

const FILTER_PRICE_MIN_KEY = 'min_price';

const FILTER_FIRMNESS_KEY = 'sofa_firmness';

const FILTER_CATEGORY_KEY = 'category';

const DEFAULT_MIN_PRICE = '0';

const DEFAULT_MAX_PRICE = '9999';

const SKIP_PAGE_KEY = 'skip_page';

const SCROLL_THRESHOLD = 0.6;

const FIRMNESS_FILTER_OPTIONS = [
  { value: 1, label: 'Soft' },
  { value: 2, label: 'Soft Plush' },
  { value: 3, label: 'Medium' },
  { value: 4, label: 'Medium Firm' },
  { value: 5, label: 'Firm' }
];

const SIZE_FILTER_CONSTANTS = {
  init: {
    minWidthCm: 0,
    maxWidthCm: 350,
    heightCm: 100,
    axisIntervalCm: 50
  },
  aspect: {
    threshold: 170,
    maxChange: 1.5,
    minChange: 0.8
  },
  axisMinWidthPx: 45,
  heightClearancePx: 15
};

const PRODUCT_PER_ROW_FOR_SM = 2;

const PRODUCT_PER_ROW_FOR_MD = 3;

const GREATER_THAN_ZERO = 'Price value must be greater than zero.';

const MAX_GREATER_MIN = 'Max price needs to be greater than min price.';

const NO_PRODUCTS = 'There are no products within this price range.';

const COLLECTIONS_SORT_BY_WIDTH = [
  4805, 3835, 9409, 3836, 3837, 5536, 5694, 5695, 1873, 9404, 9398, 5839, 9397,
  9403, 9477, 9411, 4816, 4818, 9495, 5308, 5404, 4017, 3992, 1219, 5368, 4815,
  5310, 5309, 5307, 4130, 5311, 4806, 4809, 5678, 1311, 4430, 4829, 4146, 1361,
  4126, 1273, 4429, 4831, 4828, 1275, 1034, 5600, 5602, 5604, 5603, 4637, 5335,
  5331
];

const SORT_SMALLEST_WIDTH_KEY = 'width_asc';
const SORT_BIGGEST_WIDTH_KEY = 'width_desc';

const TAXON_DEFAULT_SORT_BY = 'best_selling';
const TAXON_DEFAULT_KLEVU_SORT_BY = 'ADVANCED_SORT';
const TAXON_DEFAULT_SORT_KEY = 'TAXON_DEFAULT_SORT_KEY';
const POPULARITY_TAXON_ID_SET = new Set([
  9463, 11795, 9464, 9505, 10412, 9506, 5711, 9507
]);
const POPULARITY_HANDLE_TAXON_KEY = 'POPULARITY_HANDLE_TAXON_KEY';

const SORT_BY_OPTIONS = {
  key: 'sort',
  name: 'Sort by',
  options: [
    {
      title: 'Featured',
      value: 'RELEVANCE'
    },
    {
      title: 'Lowest Price',
      desktop_title: 'Price: Low to High',
      value: 'PRICE_ASC'
    },
    {
      title: 'Highest Price',
      desktop_title: 'Price: High to Low',
      value: 'PRICE_DESC'
    },
    {
      title: 'Popularity',
      value: 'ADVANCED_SORT',
      advancedSort: {
        key: 'popularity',
        order: 'ASC',
        type: 'FIELD'
      }
    },
    {
      title: 'Delivery Time',
      value: 'ADVANCED_SORT+1',
      advancedSort: {
        key: 'earliestDelivery',
        order: 'ASC',
        type: 'FIELD'
      }
    }
  ]
};

const TREE_LEVEL = {
  ROOT: 'root',
  MAIN: 'main',
  SUB: 'sub'
};

const PAGINATE_COLLECTIONS_KEY = 'paginate_collections';
const TEST_SMALL_UI_KEY = 'test_small_header';

export {
  atomKeys,
  NO_PRODUCTS,
  selectorKeys,
  COLLECTION_API,
  COLLECTION_EXTRA_API,
  COLLECTION_SUPPORTED_FILTER_API,
  PRODUCT_PREVIEW_API,
  MAX_GREATER_MIN,
  DEFAULT_MIN_PRICE,
  DEFAULT_MAX_PRICE,
  GREATER_THAN_ZERO,
  FILTER_COLOUR_KEY,
  TAXON_DEFAULT_SORT_BY,
  TAXON_DEFAULT_SORT_KEY,
  TAXON_DEFAULT_KLEVU_SORT_BY,
  FILTER_FIRMNESS_KEY,
  FILTER_SHIPPING_KEY,
  FILTER_PRICE_RANGE_KEY,
  FILTER_PRICE_MAX_KEY,
  FILTER_PRICE_MIN_KEY,
  FIRMNESS_FILTER_OPTIONS,
  FILTER_CATEGORY_KEY,
  FILTER_PRICE_KEY,
  SIZE_FILTER_CONSTANTS,
  PRODUCT_PER_ROW_FOR_SM,
  PRODUCT_PER_ROW_FOR_MD,
  SORT_BIGGEST_WIDTH_KEY,
  SORT_SMALLEST_WIDTH_KEY,
  CATEGORY_FILTER_LINK_REF,
  COLLECTIONS_SORT_BY_WIDTH,
  SKIP_PAGE_KEY,
  SORT_BY_OPTIONS,
  SCROLL_THRESHOLD,
  TREE_LEVEL,
  PAGINATE_COLLECTIONS_KEY,
  TEST_SMALL_UI_KEY,
  POPULARITY_TAXON_ID_SET,
  POPULARITY_HANDLE_TAXON_KEY
};
