/* eslint-disable import/no-unresolved, import/extensions */
import { useCallback, useState } from 'react';

import { sendPost } from '~/utils/requestAPI';
import { NICKNAME_VALIDATION_API } from '~/containers/Account/constants';

export default function useNicknameValidator() {
  const [isSuccess, setSuccess] = useState(false);
  const [errorMsg, setError] = useState('');

  const validateNickname = useCallback(async (nickname) => {
    let valid = true;
    try {
      if (!nickname) return valid;
      const res = await sendPost(NICKNAME_VALIDATION_API, {
        nickname
      });
      const { success, message = '' } = res.data;
      if (success) {
        setSuccess(true);
        setError('');
        valid = true;
      } else {
        setSuccess(false);
        setError(message);
        valid = false;
      }
    } catch (error) {
      HV.HipvanLogger.notify(error);
      valid = false;
    }
    return valid;
  }, []);

  return { isSuccess, errorMsg, validateNickname };
}
