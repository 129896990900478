/* eslint-disable no-param-reassign */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { isEmpty, isFunction, isFinite, noop } from 'lodash';

export const Directions = Object.freeze({
  Left: 'left',
  Right: 'right'
});

export function Arrow({
  blockName = '',
  boldIcons = false,
  direction = null,
  isDud = false,
  onClick = noop
}) {
  const onClickThumbnailNoAction = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const onClickThumbnailOnClick = useCallback(
    (event) => {
      onClickThumbnailNoAction(event);
      onClick(event);
    },
    [onClickThumbnailNoAction, onClick]
  );

  if (isEmpty(direction) || !isFunction(onClick)) return null;

  if (isDud)
    return (
      <div
        role="button"
        tabIndex={-1}
        className={`${blockName}__dud is-${direction}`}
        onClick={onClickThumbnailNoAction}
      />
    );

  return (
    <div
      role="button"
      tabIndex={-1}
      className={classNames('u-b-white u-t-body u-p-pointer', {
        [`${blockName}__arrow`]: !!blockName,
        [`is-${direction}`]: !!direction
      })}
      onClick={onClickThumbnailOnClick}
    >
      <span
        className={classNames('ic-bef ic-site-arrow', {
          [`${blockName}__icon`]: !!blockName,
          [`ic-dir-${direction}`]: !!direction,
          'ic-xbld': boldIcons
        })}
      />
    </div>
  );
}

export default function useEmblaHelper({
  emblaApi,
  onSelectCb = null,
  slidesChangedCb = null,
  actionsMap = {}
} = {}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  // Handle drag event
  const dragStartIdRef = useRef(null);
  const dragEndIdRef = useRef(null);

  const localIdRef = useRef(null);

  const [disabledPrev, setPrevBtnDisabled] = useState(true);
  const [disabledNext, setNextBtnDisabled] = useState(true);

  const onSlidesChanged = useCallback(
    (action) => {
      const currentId = emblaApi?.selectedScrollSnap();
      if (!action && !currentId) return;

      if (isFunction(slidesChangedCb))
        slidesChangedCb({
          event: action,
          currentId
        });
    },
    [emblaApi, slidesChangedCb]
  );

  const updateScrollPosition = useCallback(
    (action, direction, index) => {
      if (!emblaApi) return;

      switch (direction) {
        case Directions.Left: {
          emblaApi.scrollPrev();
          onSlidesChanged(action);
          break;
        }
        case Directions.Right: {
          emblaApi.scrollNext();
          onSlidesChanged(action);
          break;
        }
        default: {
          const number = Number(index);
          if (isFinite(number)) {
            emblaApi.scrollTo(index);
            onSlidesChanged(action);
          }
          break;
        }
      }
    },
    [emblaApi, onSlidesChanged]
  );

  const onClickPrev = useCallback(() => {
    updateScrollPosition(actionsMap?.left_arrow, Directions.Left, null);
  }, [actionsMap?.left_arrow, updateScrollPosition]);

  const onClickNext = useCallback(() => {
    updateScrollPosition(actionsMap?.right_arrow, Directions.Right, null);
  }, [actionsMap?.right_arrow, updateScrollPosition]);

  const onClickThumbnail = useCallback(
    (index) => {
      updateScrollPosition(actionsMap?.thumbs_lane, undefined, index);
    },
    [actionsMap?.thumbs_lane, updateScrollPosition]
  );

  const onClickDot = useCallback(
    (index) => {
      updateScrollPosition(actionsMap?.nav_dots, undefined, index);
    },
    [actionsMap?.nav_dots, updateScrollPosition]
  );

  const onCustomUpdate = useCallback(
    (index) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  const didStartDragging = useCallback((api) => {
    dragStartIdRef.current = api.selectedScrollSnap();
  }, []);

  const didEndDragging = useCallback(
    (api) => {
      dragEndIdRef.current = api.selectedScrollSnap();
      const didChangeId = dragStartIdRef.current !== dragEndIdRef.current;
      if (didChangeId) {
        onSlidesChanged(actionsMap?.touch_drag);
      }
      dragStartIdRef.current = null;
      dragEndIdRef.current = null;
    },
    [actionsMap?.touch_drag, onSlidesChanged]
  );

  const onSelect = useCallback(
    (api) => {
      const latestId = api.selectedScrollSnap();
      setSelectedIndex(latestId);
      setPrevBtnDisabled(!api.canScrollPrev());
      setNextBtnDisabled(!api.canScrollNext());

      localIdRef.current = latestId;

      if (isFunction(onSelectCb)) {
        onSelectCb({
          selected: latestId,
          visible: api.slidesInView()
        });
      }
    },
    [onSelectCb]
  );

  const onInit = useCallback((api) => setScrollSnaps(api.scrollSnapList()), []);

  useEffect(() => {
    if (!emblaApi) {
      return () => {};
    }
    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi
      .on('pointerDown', didStartDragging)
      .on('pointerUp', didEndDragging);
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect);

    return () => {
      emblaApi
        .off('pointerDown', didStartDragging)
        .off('pointerUp', didEndDragging);
      emblaApi
        .off('reInit', onInit)
        .off('reInit', onSelect)
        .off('select', onSelect);
    };
  }, [didEndDragging, didStartDragging, emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    disabledPrev,
    disabledNext,
    onClickPrev,
    onClickNext,
    onClickThumbnail,
    onClickDot,
    onCustomUpdate
  };
}
