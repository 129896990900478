import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isEmpty, noop } from 'lodash';

import { FACEBOOK_LOGIN_API } from '~/containers/ModalsManager/constants';
import atoms from '~/containers/shared/states/atoms';
import modalAtoms from '~/containers/ModalsManager/states/atoms';
import { submit } from '~/utils/requestAPI';
import { trackAuthResponse, trackAuthLead } from '~/utils/analytics/gtm';
import { SOURCE_DEVICE_CATEGORY } from '~/containers/shared/constants';

export default function useFacebookSignIn({
  authProvider,
  handleClose = noop
}) {
  const isClient = useRecoilValue(atoms.isClient);
  const setErrorMess = useSetRecoilState(modalAtoms.authErrorMessage);
  const authConfig = useRecoilValue(modalAtoms.authConfig);

  const facebookLogin = useCallback(
    async (response) => {
      try {
        const { pathname = '' } = window.location;
        const { accessToken, expiresIn, error, status } = response;

        if (error || status === 'unknown') {
          return;
        }

        const body = {
          'facebook[token]': accessToken,
          'facebook[expires_in]': expiresIn,
          source_path: isClient ? pathname : '',
          source_device_category: SOURCE_DEVICE_CATEGORY
        };
        const { data = {} } = await submit(FACEBOOK_LOGIN_API, body);

        if (!isEmpty(data)) {
          const { success, message } = data;
          if (success) {
            trackAuthLead({
              mode: authConfig?.authMode,
              content: authConfig?.authContent,
              authProvider
            });
            trackAuthResponse({
              authProvider,
              response: 'success'
            });
            handleClose();
            if (isClient) window.location.reload();
          } else {
            trackAuthResponse({
              authProvider,
              response: `invalid - ${message}`
            });
            setErrorMess(message);
          }
        }
      } catch (error) {
        trackAuthResponse({
          authProvider,
          response: 'failure'
        });
        HV.HipvanLogger.notify(error);
      }
    },
    [
      authConfig?.authContent,
      authConfig?.authMode,
      authProvider,
      handleClose,
      isClient,
      setErrorMess
    ]
  );

  return facebookLogin;
}
