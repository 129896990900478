import keyMirror from 'keymirror';

import { commonParams, squareAr } from '~/utils/imgix';

const DEFAULT_NAV_HEIGHT = 80;
const NAV_TOP_THRESHOLD = 200;

const atomKeys = keyMirror({
  NAVIGATION: null,
  SCROLL_DATA: null,
  ROOT_HOVER: null,
  BURGER_OPEN: null,
  BURGER_BROKEN_IMAGES: null,
  RECALC_ON_FONTS_LOAD: null,
  LIST_FILTER_OPEN: null,
  SEARCH_FOCUS: null,
  DROPDOWN_OPEN: null,
  SHADE_OVERLAY_OPEN: null,
  CART_SESSIONS: null,
  NAV_SEARCH_QUERY: null,
  NAV_CUSTOM_PLACEHOLDER: null,
  KLEVU_NAV_SEARCH_RESULTS: null,
  KLEVU_TOP_TERMS: null,
  POPULAR_KEYWORDS: null
});

const selectorKeys = keyMirror({
  TOTAL_ITEMS_IN_CART: null,
  NAV_SEARCH_SUGGESTION_LIST: [],
  SEARCH_FOCUS_DROPDOWN_OPEN: null
});

const HELMET_CONTEXT = {};

const keyboardKeys = keyMirror({
  Enter: null,
  ArrowUp: null,
  ArrowDown: null,
  Tab: null
});

const SEARCH_SUGGESTIONS_API = '/search/suggestions';
const KLEVU_COLLECTIONS_SEARCH_API = '/klevu_collections/search';

const BURGER_TITLE = 'Shop by Categories';
const BURGER_THUMB_WIDTH = 100;

const burgerImgixParams = {
  ...commonParams,
  ar: squareAr,
  fit: 'crop',
  w: BURGER_THUMB_WIDTH,
  bg: 'fff'
};

const RECENT_SEARCH_TERMS = 'recent_search_terms';

const MAX_RECENT_SEARCHES = 30;

const DISPLAY_RECENT_SEARCHES = 5;

const HEADER_INLINE_SEARCH_BAR_KEY = 'header_inline_search_bar';

const DISPLAY_MATCHING_PRODUCTS = 3;

export {
  atomKeys,
  selectorKeys,
  keyboardKeys,
  HELMET_CONTEXT,
  DEFAULT_NAV_HEIGHT,
  NAV_TOP_THRESHOLD,
  SEARCH_SUGGESTIONS_API,
  KLEVU_COLLECTIONS_SEARCH_API,
  BURGER_TITLE,
  BURGER_THUMB_WIDTH,
  burgerImgixParams,
  RECENT_SEARCH_TERMS,
  MAX_RECENT_SEARCHES,
  DISPLAY_RECENT_SEARCHES,
  HEADER_INLINE_SEARCH_BAR_KEY,
  DISPLAY_MATCHING_PRODUCTS
};
