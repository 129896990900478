import { useMemo, useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import queryString from 'query-string-for-all';
import axios from 'axios';
import { isEmpty } from 'lodash';

import {
  DISPLAY_RECENT_SEARCHES,
  RECENT_SEARCH_TERMS,
  SEARCH_SUGGESTIONS_API
} from '~/containers/Header/constants';
import {
  RESULT_PAGE_URL,
  SUGGEST_SEARCH_TYPES
} from '~/containers/SearchResult/constants';
import atoms from '~/containers/Header/states/atoms';
import { defaultForUndefinedOrNull, defaultForFalsy } from '~/utils/helper';
import { SUCCESS_CODE, sendGet } from '~/utils/requestAPI';
import { localStorageHelper } from '~/utils/storageHelper';

export default function useSearchSuggestions(searchQuery = '') {
  const klevuNavSearchResults = useRecoilValue(atoms.klevuNavSearchResults);

  const [popularKeywords, setPopularKeywords] = useRecoilState(
    atoms.popularKeywords
  );

  const [recentSearches, setRecentSearches] = useState([]);

  const trimmedQuery = useMemo(
    () => defaultForUndefinedOrNull(searchQuery, '').trim(),
    [searchQuery]
  );

  const noQuery = useMemo(() => isEmpty(trimmedQuery), [trimmedQuery]);

  const klevuResults = useMemo(
    () => defaultForUndefinedOrNull(klevuNavSearchResults, []),
    [klevuNavSearchResults]
  );

  const searchHrefFor = (searchType = '', name = '', url = '') => {
    switch (searchType) {
      case SUGGEST_SEARCH_TYPES.hv_term: {
        return queryString.stringifyUrl({
          url: RESULT_PAGE_URL,
          query: { q: name }
        });
      }
      default: {
        return queryString.stringifyUrl({
          url,
          query: { opt: searchType }
        });
      }
    }
  };

  const klevuSuggestions = useMemo(() => {
    const searchType = SUGGEST_SEARCH_TYPES.klevu;

    return klevuResults.slice(0, 8).map((opt) => {
      return {
        ...opt,
        ...JSON.parse(defaultForFalsy(opt?.additionalDataToReturn, '{}')),
        search_type: searchType,
        formatted_url: searchHrefFor(searchType, opt?.name, opt?.url)
      };
    });
  }, [klevuResults]);

  const popularSuggestions = useMemo(
    () =>
      popularKeywords.map((opt) => {
        const searchType =
          SUGGEST_SEARCH_TYPES[opt?.custom_link ? 'hv_link' : 'hv_term'];

        return {
          id: opt?.position.toString(),
          name: opt?.label,
          url: opt?.custom_link,
          source: opt?.source_tag,
          search_type: searchType,
          prefix: opt?.prefix,
          formatted_url: searchHrefFor(searchType, opt?.label, opt?.custom_link)
        };
      }),
    [popularKeywords]
  );

  const localRecentSearches = localStorageHelper.getItem(RECENT_SEARCH_TERMS);

  const updateRecentSearches = useCallback(() => {
    try {
      const results = JSON.parse(localRecentSearches) || [];
      setRecentSearches(results.slice(-DISPLAY_RECENT_SEARCHES));
    } catch (error) {
      HV.HipvanLogger.notify(
        'Error parsing recent search terms from localStorage',
        {
          info: error
        }
      );
    }
  }, [localRecentSearches]);

  useEffect(() => {
    updateRecentSearches();

    const handleStorageChange = (event) => {
      if (event.key === RECENT_SEARCH_TERMS) {
        updateRecentSearches();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [updateRecentSearches]);

  const suggestions = useMemo(
    () =>
      (noQuery && !isEmpty(popularKeywords)) || isEmpty(klevuResults)
        ? popularSuggestions
        : klevuSuggestions,
    [
      klevuResults,
      klevuSuggestions,
      noQuery,
      popularKeywords,
      popularSuggestions
    ]
  );

  const popularKeywordsRequest = useCallback(
    async (source) => {
      try {
        const response = await sendGet(
          SEARCH_SUGGESTIONS_API,
          {},
          { cache: false, cancelToken: source.token }
        );
        const { status, data: { data: suggs = [] } = {} } = response || {};
        if (status === SUCCESS_CODE) {
          setPopularKeywords(suggs);
        } else if (status > 0) {
          HV.HipvanLogger.notify('Search suggestions not available', {
            info: response
          });
        }
      } catch (error) {
        HV.HipvanLogger.notify(error);
      }
    },
    [setPopularKeywords]
  );

  useEffect(() => {
    const cancelSource = axios.CancelToken;
    const source = cancelSource.source();

    popularKeywordsRequest(source);

    return () => {
      source.cancel('cancel popularKeywordsRequest effect');
    };
  }, [popularKeywordsRequest]);

  return {
    noQuery,
    trimmedQuery,
    searchHrefFor,
    klevuSuggestions,
    popularSuggestions,
    suggestions,
    recentSearches
  };
}
