import { useState, useMemo, useCallback, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { DateTime, Duration } from 'luxon';
import { useCookies } from 'react-cookie';
import { useMediaQuery } from 'react-responsive';
import {
  isEmpty,
  isObject,
  isEqual,
  find,
  noop,
  merge,
  isPlainObject
} from 'lodash';

import RailsVars from '~/apps/railsVariables.js.erb';
import {
  SESSION_KEY,
  PREV_SESSION_KEY,
  CALLOUT_IMPRESSION_DELAY,
  CALLOUT_COUNTS_CACHE,
  DEFAULT_LEADSUB_PAUSE_DURA,
  LEAD_SUB_CONFIG_KEY,
  LEADSUB_PAUSE_KEY,
  LEADSUB_COUNT_KEY,
  LEADSUB_SUCCESS_KEY,
  LEADSUB_VARIANT_KEY,
  STICKY_PROMO_CONFIG_KEY,
  STICKY_PROMO_PAUSE_KEY,
  STICKY_PROMO_COUNT_KEY,
  STICKY_PROMO_VARIANT_KEY,
  DEFAULT_APP_NUDGE_PAUSE_DURA,
  APP_NUDGE_CONFIG_KEY,
  APP_NUDGE_PAUSE_KEY,
  APP_NUDGE_COUNT_KEY,
  APP_NUDGE_VARIANT_KEY,
  EXIT_INTENT_CONFIG_KEY,
  EXIT_INTENT_PAUSE_KEY,
  EXIT_INTENT_COUNT_KEY
} from '~/containers/ModalsManager/constants';
import {
  BLOG_INDEX_PATH,
  PICTURES_HANDLE_PATH,
  PICTURES_NEW_PATH
} from '~/containers/Blog/constants';
import { mediaQueryPreset } from '~/containers/shared/constants';
import atoms from '~/containers/shared/states/atoms';
import checkMobileUa from '~/hooks/shared/useExitIntent/utils/is/mobile';
import {
  sessionStorageHelper,
  sessionStorageEvent,
  localStorageHelper,
  localStorageEvent
} from '~/utils/storageHelper';
import {
  defaultForUndefinedOrNull,
  humanListCount,
  currentTimeVisible,
  currentTimePrefer
} from '~/utils/helper';
import {
  MATTRESS_CMS_PAGE_PATH,
  SHOWROOM_CMS_PAGE_PATH
} from '~/utils/pagePaths';
import { trackCalloutAction } from '~/containers/ModalsManager/analytics';

const DEFAULT_COUNTS = {
  [STICKY_PROMO_COUNT_KEY]: 0,
  [APP_NUDGE_COUNT_KEY]: 0,
  [LEADSUB_COUNT_KEY]: 0,
  [EXIT_INTENT_COUNT_KEY]: 0
};

const escapeStringRegex = (str = '') =>
  str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');

const safeDuration = (obj, fallback) => {
  const safeValue = (val, def) =>
    isObject(val) && Duration.fromObject(val).isValid ? val : def;
  return safeValue(obj, safeValue(fallback, { days: 1 }));
};

export const useCalloutViewingEvents = ({
  impressionable = false,
  intersecting = true,
  countProp = '',
  closeEvent = 'unknown_useCalloutViewingEvents',
  impressionEvent = 'unknown_useCalloutViewingEvents',
  payload = {},
  seen = false,
  setSeen = noop,
  impressionCb = noop,
  beforeunloadCb = noop
} = {}) => {
  const [memoPayload, setMemoPayload] = useState({});

  const incrementViewCount = useCallback(() => {
    const cachedCounts = defaultForUndefinedOrNull(
      localStorageHelper.getItem(CALLOUT_COUNTS_CACHE),
      {}
    );
    localStorageHelper.setItem(CALLOUT_COUNTS_CACHE, {
      ...cachedCounts,
      [countProp]: defaultForUndefinedOrNull(cachedCounts?.[countProp], 0) + 1
    });
  }, [countProp]);

  const trackOnClose = useCallback(
    (acted) =>
      trackCalloutAction(closeEvent, { ...memoPayload, acted }, () => {
        setSeen(true);
        incrementViewCount(countProp);
      }),
    [closeEvent, countProp, incrementViewCount, memoPayload, setSeen]
  );

  const trackBeforeunload = useCallback(
    (acted) => {
      beforeunloadCb();
      if (intersecting && !seen) trackOnClose(acted);
    },
    [beforeunloadCb, intersecting, seen, trackOnClose]
  );

  useEffect(() => {
    setMemoPayload((prev) => (isEqual(payload, prev) ? prev : payload));
  }, [payload]);

  useEffect(() => {
    let impression;

    if (impressionable && intersecting && !seen) {
      impression = setTimeout(
        () =>
          trackCalloutAction(
            impressionEvent,
            memoPayload,
            impressionCb(memoPayload)
          ),
        CALLOUT_IMPRESSION_DELAY
      );
    }

    return () => {
      clearTimeout(impression);
    };
  }, [
    impressionCb,
    impressionEvent,
    impressionable,
    intersecting,
    memoPayload,
    seen
  ]);

  return { trackOnClose, trackBeforeunload };
};

export default function useCalloutsData(pathname = '') {
  const [cookies, setCookie] = useCookies([
    SESSION_KEY,
    STICKY_PROMO_VARIANT_KEY,
    APP_NUDGE_PAUSE_KEY,
    APP_NUDGE_VARIANT_KEY,
    LEADSUB_PAUSE_KEY,
    LEADSUB_VARIANT_KEY,
    EXIT_INTENT_PAUSE_KEY
  ]);
  const isMobile = useMediaQuery(mediaQueryPreset.mobile);
  const isClient = useRecoilValue(atoms.isClient);

  const [isMobileUa, setIsMobileUa] = useState(checkMobileUa());
  const [currentPageMeta, setCurrentPageMeta] = useState({});
  const [isCollectionPage, setIsCollectionPage] = useRecoilState(
    atoms.isCollectionPage
  );
  const isProductPage = useRecoilValue(atoms.isProductPage);

  const [calloutViews, setCalloutViews] = useState(DEFAULT_COUNTS);

  const selectTestVariant = useCallback(
    (obj, cookieKey) => {
      const opts = defaultForUndefinedOrNull(obj?.test, []);
      if (!obj?.test || !Array.isArray(obj?.test)) return obj;

      const activeOpt = find(opts, (op) => op?.name === cookies?.[cookieKey]);
      return isPlainObject(activeOpt) ? merge({}, obj, activeOpt) : obj;
    },
    [cookies]
  );

  useEffect(() => {
    setIsMobileUa(checkMobileUa());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalThis?.isMobile?.phone]);

  // --------------------
  // Sticky Promo
  // { interval, messages: [<obj>, ...] }
  // obj: { <test>, image_mobl, image_desk, image_tabl, color_bg, color_text, title, link, target, start, end }
  // test: [{ name, ...<obj> }, ...]
  // --------------------

  const [stickyPromoConfig, setStickyPromoConfig] = useState({});

  const stickyPromoData = useMemo(() => {
    const { interval, messages = [] } = stickyPromoConfig;

    const isMattressCMS = pathname.indexOf(MATTRESS_CMS_PAGE_PATH) > -1;
    const isShowroomCMS = pathname.indexOf(SHOWROOM_CMS_PAGE_PATH) > -1;

    const isCLP = new RegExp(
      `^${escapeStringRegex(BLOG_INDEX_PATH)}($|\\/$|[?#].+|[?#])`
    ).test(pathname);
    const isNonMobileCDP =
      new RegExp(
        `^${escapeStringRegex(
          PICTURES_HANDLE_PATH
        )}\\/([0-9a-f]{24}|.+-[0-9a-z]{12})($|\\/$|[?#].+|[?#])`
      ).test(pathname) && !isMobile;

    const safePaths =
      isCollectionPage ||
      isProductPage ||
      isMattressCMS ||
      isShowroomCMS ||
      isNonMobileCDP ||
      isCLP;
    const skipToShow =
      sessionStorageHelper.getItem(STICKY_PROMO_PAUSE_KEY) ||
      !Array.isArray(messages);
    const safeList = messages
      .map((o) => selectTestVariant(o, STICKY_PROMO_VARIANT_KEY))
      .filter(
        ({ image_mobl: moblSrc, image_desk: deskSrc, start, end }) =>
          currentTimeVisible(start, end) &&
          ((isMobile && !isEmpty(moblSrc)) || (!isMobile && !isEmpty(deskSrc)))
      )
      .map((props, idx) => ({ ...props, position: humanListCount(idx) }));

    if (isEmpty(stickyPromoConfig) || isEmpty(stickyPromoConfig?.messages))
      return {};
    return safePaths && !skipToShow && !isEmpty(safeList)
      ? {
          interval,
          messages: safeList,
          viewCount: calloutViews?.[STICKY_PROMO_COUNT_KEY]
        }
      : {};
  }, [
    calloutViews,
    isCollectionPage,
    isMobile,
    isProductPage,
    pathname,
    selectTestVariant,
    stickyPromoConfig
  ]);

  // --------------------
  // App Nudge
  // { default: <obj>, others: [<obj>, ...], retry }
  // obj: { <test>, image, title, link, target, start, end, closeInMs }
  // test: [{ name, ...<obj> }, ...]
  // --------------------

  const [appNudgeConfig, setAppNudgeConfig] = useState({});

  const appNudgeData = useMemo(() => {
    const { default: main = {}, others = [], retry } = appNudgeConfig;

    const validNudge = (o) => !isEmpty(o?.image) && !isEmpty(o?.link);
    const currentMain =
      isObject(main) && !isEmpty(main)
        ? selectTestVariant(main, APP_NUDGE_VARIANT_KEY)
        : {};
    const safeMain = validNudge(currentMain) ? currentMain : {};
    const safeOther = Array.isArray(others)
      ? currentTimePrefer(
          others
            .map((o) => selectTestVariant(o, APP_NUDGE_VARIANT_KEY))
            .filter(
              (o) => validNudge(o) && currentTimeVisible(o?.start, o?.end)
            ),
          'start',
          false
        )
      : [];
    const safeFinal = isEmpty(safeOther) ? safeMain : safeOther;
    const safeRetry = safeDuration(retry, DEFAULT_APP_NUDGE_PAUSE_DURA);
    const skipToShow =
      !!cookies?.[APP_NUDGE_PAUSE_KEY] ||
      !isMobileUa ||
      pathname.indexOf(PICTURES_NEW_PATH) > -1;

    if (isEmpty(appNudgeConfig)) return {};
    return !skipToShow && !isEmpty(safeFinal)
      ? { ...safeFinal, retry: safeRetry }
      : {};
  }, [appNudgeConfig, cookies, isMobileUa, pathname, selectTestVariant]);

  useEffect(() => {
    const safeRetry = safeDuration(
      appNudgeConfig?.retry,
      DEFAULT_APP_NUDGE_PAUSE_DURA
    );
    if (!!cookies?.[APP_NUDGE_PAUSE_KEY] && isEmpty(appNudgeData))
      setCookie(APP_NUDGE_PAUSE_KEY, 1, {
        expires: DateTime.now().plus(safeRetry).toJSDate(),
        path: '/',
        sameSite: 'Strict'
      });
  }, [appNudgeConfig?.retry, appNudgeData, cookies, setCookie]);

  // --------------------
  // Lead Sub
  // { ...<obj> }
  // obj: { <test>, tagline, title, header, image, retry }
  // test: [{ name, ...<obj> }, ...]
  // --------------------

  const [leadSubConfig, setLeadSubConfig] = useState({});

  const leadSubData = useMemo(() => {
    const skipToShow =
      localStorageHelper.getItem(LEADSUB_SUCCESS_KEY) ||
      !!cookies?.[LEADSUB_PAUSE_KEY];
    const currentData = selectTestVariant(leadSubConfig, LEADSUB_VARIANT_KEY);
    const hasRequiredInfo = !isEmpty(currentData?.title);
    const safeRetry = safeDuration(
      currentData?.retry,
      DEFAULT_LEADSUB_PAUSE_DURA
    );

    if (isEmpty(leadSubConfig)) return {};
    return !skipToShow && hasRequiredInfo
      ? merge({}, currentData, {
          retry: safeRetry,
          viewCount: calloutViews?.[LEADSUB_COUNT_KEY]
        })
      : {};
  }, [calloutViews, cookies, leadSubConfig, selectTestVariant]);

  // --------------------
  // Exit Intent
  // { effect, code, config }
  // --------------------

  const [exitIntentConfig, setExitIntentConfig] = useState({});

  const exitIntentData = useMemo(() => {
    const { effect, code } = exitIntentConfig;

    const safePaths = pathname.indexOf(BLOG_INDEX_PATH) < 0;
    const skipToShow = !!cookies?.[EXIT_INTENT_PAUSE_KEY] || isMobileUa;
    const hasRequiredInfo = !isEmpty(effect) && !isEmpty(code);

    if (isEmpty(exitIntentConfig)) return {};
    return safePaths && !skipToShow && hasRequiredInfo
      ? merge({}, exitIntentConfig, {
          viewCount: calloutViews?.[EXIT_INTENT_COUNT_KEY],
          config: { cookie: { key: EXIT_INTENT_PAUSE_KEY } }
        })
      : {};
  }, [calloutViews, cookies, exitIntentConfig, isMobileUa, pathname]);

  // --------------------
  // Fetch Data
  // --------------------

  useEffect(() => {
    setIsCollectionPage(currentPageMeta?.cg1 === 'collection');
  }, [currentPageMeta?.cg1, setIsCollectionPage]);

  const checkSessionChange = useCallback(() => {
    const currentId = isClient ? cookies?.[SESSION_KEY] : null;
    const previousId = (() => localStorageHelper.getItem(PREV_SESSION_KEY))();

    if (
      !isEmpty(previousId) &&
      !isEmpty(currentId) &&
      previousId !== currentId
    ) {
      localStorageHelper.setItem(CALLOUT_COUNTS_CACHE, DEFAULT_COUNTS);
      localStorageHelper.setItem(PREV_SESSION_KEY, currentId);
    }

    if (isEmpty(previousId))
      localStorageHelper.setItem(PREV_SESSION_KEY, currentId);
  }, [cookies, isClient]);

  useEffect(() => {
    checkSessionChange();
  }, [checkSessionChange]);

  useEffect(() => {
    const updatePageViewData = () => {
      setCurrentPageMeta(
        defaultForUndefinedOrNull(
          sessionStorageHelper.getItem(RailsVars.PAGE_VIEW_KEY)?.currentPage,
          {}
        )
      );
    };

    const updateViewCounts = () =>
      setCalloutViews(
        defaultForUndefinedOrNull(
          localStorageHelper.getItem(CALLOUT_COUNTS_CACHE),
          DEFAULT_COUNTS
        )
      );

    updateViewCounts();

    document.addEventListener(sessionStorageEvent, updatePageViewData);
    document.addEventListener(localStorageEvent, updateViewCounts);

    return () => {
      document.removeEventListener(sessionStorageEvent, updatePageViewData);
      document.removeEventListener(localStorageEvent, updateViewCounts);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(globalThis?.[APP_NUDGE_CONFIG_KEY]))
      setAppNudgeConfig(globalThis[APP_NUDGE_CONFIG_KEY]);

    if (!isEmpty(globalThis?.[EXIT_INTENT_CONFIG_KEY]))
      setExitIntentConfig(globalThis[EXIT_INTENT_CONFIG_KEY]);

    if (!isEmpty(globalThis?.[LEAD_SUB_CONFIG_KEY]))
      setLeadSubConfig(globalThis[LEAD_SUB_CONFIG_KEY]);

    if (!isEmpty(globalThis?.[STICKY_PROMO_CONFIG_KEY]))
      setStickyPromoConfig(globalThis[STICKY_PROMO_CONFIG_KEY]);
  }, []);

  return {
    stickyPromoData,
    appNudgeConfig,
    appNudgeData,
    leadSubConfig,
    leadSubData,
    exitIntentData
  };
}
