import React, { useCallback } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { noop } from 'lodash';

import RailsVars from '~/apps/railsVariables.js.erb';
import { trackClickAuthMethod } from '~/utils/analytics/gtm';
import useFacebookSignIn from '~/hooks/shared/useFacebookSignIn';
import Spinner from '~/components/shared/Spinner';
import './ExternalAuth.scss';

export const AUTH_PROVIDER = 'facebook';

export function ContinueWithFacebookButton({
  handleClick = noop,
  callback = noop,
  isLoading = false
}) {
  return (
    <FacebookLogin
      appId={RailsVars.FACEBOOK_APP_ID}
      autoLoad={false}
      callback={callback}
      fields="name,email,picture"
      render={(renderProps) => (
        <button
          onClick={(e) => handleClick(e, renderProps?.onClick)}
          type="button"
          className={`c-fbAuth o-btn o-fd u-p-pointer u-t-nooutline ${
            isLoading ? 'disabled' : ''
          }`}
        >
          {isLoading ? (
            <div className="c-fbAuth__loading">
              <Spinner />
            </div>
          ) : (
            <>
              <span className="c-fbAuth__icon u-inline-block ic-bef ic-socl-facebook ic-solid" />
              <span className="c-fbAuth__text u-inline-block">
                Continue with Facebook
              </span>
            </>
          )}
        </button>
      )}
    />
  );
}

export default function FacebookAuthButton({ handleClose = noop }) {
  const facebookLogin = useFacebookSignIn({
    authProvider: AUTH_PROVIDER,
    handleClose
  });

  const handleClick = useCallback((event, fhOnCLick = noop) => {
    event.preventDefault();
    trackClickAuthMethod(AUTH_PROVIDER);
    fhOnCLick();
  }, []);

  return (
    <ContinueWithFacebookButton
      handleClick={handleClick}
      callback={facebookLogin}
    />
  );
}
