import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Imgix, { buildURL } from 'react-imgix';
import classNames from 'classnames';
import { isEmpty, noop } from 'lodash';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import {
  BURGER_THUMB_WIDTH,
  burgerImgixParams,
  MAX_RECENT_SEARCHES,
  RECENT_SEARCH_TERMS
} from '~/containers/Header/constants';
import {
  notFoundSrc,
  placeholdSrc,
  cleanUrlToImgix,
  lazyAttribs
} from '~/utils/imgix';
import { ga4Events } from '~/utils/analytics/gtm';
import {
  trackSearchSeeAll,
  trackSearchSuggestAction
} from '~/containers/SearchResult/analytics';
import { localStorageHelper } from '~/utils/storageHelper';
import { defaultForUndefinedOrNull, formatPrice } from '~/utils/helper';
import './Overlay.scss';

function OverlayBody({ innerRef, height, onScrollCb = noop, children = null }) {
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);

  // Show shadow at top & bottom edges
  // when there is scroll overflow
  const handleScroll = useCallback(() => {
    if (innerRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef.current;
      setAtEnd(scrollHeight - 1 <= scrollTop + clientHeight);
      setAtStart(scrollTop === 0);
      onScrollCb();
    }
  }, [innerRef, onScrollCb]);

  if (isEmpty(children)) return null;

  return (
    <div className="c-nvsOverlay__drop u-b-white">
      <div className="c-nvsOverlay__drop-wrap" style={{ height }}>
        <div
          className={`c-nvsOverlay__drop-cap is-head ${
            atStart ? '' : 'has-shade'
          }`}
        >
          <div className="c-nvsOverlay__drop-shade" />
        </div>
        <div
          className="c-nvsOverlay__drop-well"
          ref={innerRef}
          onScroll={handleScroll}
        >
          {children}
        </div>
        <div
          className={`c-nvsOverlay__drop-cap is-foot ${
            atEnd ? '' : 'has-shade'
          }`}
        >
          <div className="c-nvsOverlay__drop-shade" />
        </div>
      </div>
    </div>
  );
}

function SubSection({
  query = '',
  heading = null,
  total = 0,
  link = '',
  modifier = '',
  children = null
}) {
  const handleClickSeeAll = useCallback(() => {
    trackSearchSeeAll(ga4Events?.click_search_see_all, {
      url: link,
      query,
      total,
      trigger: 'suggest'
    });
  }, [link, query, total]);

  if (isEmpty(children)) return null;

  return (
    <div
      className={classNames('c-nvsOverlay__blk', {
        [modifier]: !!modifier
      })}
    >
      {!isEmpty(heading) && (
        <div className="c-nvsOverlay__blk-head">
          <span className="c-nvsOverlay__blk-title u-t-grey">
            {!!total && (
              <span className="c-nvsOverlay__blk-total u-t-bold">{total}</span>
            )}
            {heading}
          </span>
          {!isEmpty(link) && (
            <a
              className="c-nvsOverlay__blk-link u-t-grey"
              href={link}
              onClick={handleClickSeeAll}
            >
              See all
              <span
                className="c-nvsOverlay__blk-icon ic-bef ic-site-arrow ic-dir-right u-p-pointer"
                aria-hidden="true"
              />
            </a>
          )}
        </div>
      )}
      <div className="c-nvsOverlay__body">{children}</div>
    </div>
  );
}

function NoMatches({ keyword }) {
  return (
    <div className="c-nvsOverlay__zero">
      <div className="c-nvsOverlay__zero-h u-t-grey">
        No results matching &ldquo;
        <span className="u-t-breakword">{keyword}</span>
        &rdquo;
      </div>
      <div className="c-nvsOverlay__zero-p u-t-greymid u-t-small">
        Please check spelling or try using different keywords.
      </div>
    </div>
  );
}

function TaxonMatch({
  itemSrc,
  itemName,
  itemHighlight = '',
  itemCount = 0,
  itemPrice = 0,
  itemSalePrice = 0
}) {
  const { t } = useTranslation('products');
  const [error, setError] = useState(false);
  const cleanSrc = useMemo(() => cleanUrlToImgix(itemSrc), [itemSrc]);

  const fallbackImgUrl = useMemo(
    () =>
      buildURL(placeholdSrc, {
        ...burgerImgixParams,
        w: BURGER_THUMB_WIDTH * 0.1
      }),
    []
  );

  return (
    <figure className="c-nvsOverlay__opt-fig">
      <div className="c-nvsOverlay__opt-thumb">
        <span className="c-nvsOverlay__opt-cast" />
        <Imgix
          className="c-nvsOverlay__opt-img u-block lazyload"
          sizes={`${BURGER_THUMB_WIDTH}px`}
          disableSrcSet
          src={error ? notFoundSrc : cleanSrc}
          imgixParams={burgerImgixParams}
          attributeConfig={lazyAttribs}
          htmlAttributes={{
            alt: itemName,
            src: fallbackImgUrl,
            onError: () => setError(true)
          }}
        />
      </div>
      <figcaption className="c-nvsOverlay__opt-caption">
        <div className="c-nvsOverlay__opt-label">{itemHighlight}</div>
        {!!itemCount && (
          <div className="c-nvsOverlay__opt-count u-t-greymid u-t-smaller">{`${itemCount} ${t(
            'products:product.unit',
            {
              count: itemCount
            }
          )}`}</div>
        )}
        {!!itemPrice && (
          <div className="c-nvsOverlay__opt-price u-t-bold">
            {itemSalePrice !== itemPrice && (
              <>
                <h5 className="c-nvsOverlay__opt-salePrice u-inline-block is-emp u-t-white u-b-secondary">
                  {t('common:showPrice', {
                    val: formatPrice(itemSalePrice)
                  })}
                </h5>{' '}
              </>
            )}
            <span
              className={classNames(
                'c-nvsOverlay__opt-finalPrice u-inline-block',
                { 'u-t-strike': itemSalePrice !== itemPrice }
              )}
            >
              {t('common:showPrice', {
                val: formatPrice(itemPrice)
              })}
            </span>
          </div>
        )}
      </figcaption>
    </figure>
  );
}

function getSearchType(pathOrUrl) {
  try {
    const url = new URL(pathOrUrl, globalThis.HV.BASE_LAC_LONG_QUAN_URL);
    const urlParams = url.searchParams;
    return defaultForUndefinedOrNull(urlParams.get('opt'), '');
  } catch (error) {
    HV.HipvanLogger.notify('Invalid Path/URL', {
      info: error
    });
    return null;
  }
}

function getLocalStorageData(key, defaultValue = []) {
  try {
    const storedData = localStorageHelper.getItem(key);
    if (storedData) {
      return JSON.parse(storedData);
    }
    return defaultValue;
  } catch (error) {
    HV.HipvanLogger.notify('Error parsing JSON from localStorage', {
      info: error
    });
    return defaultValue;
  }
}

export function removeRecentSearchById(key, id) {
  const existingData = getLocalStorageData(key, []);
  const filteredData = existingData.filter((item) => item.id !== id);

  localStorageHelper.setItem(
    key,
    JSON.stringify(isEmpty(filteredData) ? null : filteredData)
  );
}

export function saveRecentSearch(key, data) {
  const existingData = getLocalStorageData(key, []);

  const index = existingData.findIndex((item) => item.name === data.name);
  if (index !== -1) {
    existingData.splice(index, 1);
  }

  if (existingData.length >= MAX_RECENT_SEARCHES) {
    existingData.splice(0, 1);
  }

  existingData.push({
    ...data,
    id: new Date().getTime(),
    search_type: getSearchType(data?.formatted_url)
  });

  localStorageHelper.setItem(key, JSON.stringify(existingData));
}

const trackingItemData = (query, item = {}) => ({
  query,
  title: item?.name,
  url: item?.formatted_url,
  mode: item?.search_type
});

function KlevuSuggestion({
  query,
  item = {},
  listPosition,
  totalResults = 0,
  isCollection = false,
  children = null
}) {
  const handleClickKlevuResult = useCallback(() => {
    if (isCollection) {
      saveRecentSearch(RECENT_SEARCH_TERMS, {
        name: item?.name,
        formatted_url: item?.formatted_url
      });
    }
    trackSearchSuggestAction(ga4Events?.click_search_suggest, {
      ...trackingItemData(query, item),
      total: totalResults,
      listPosition,
      trigger: 'suggest'
    });
  }, [isCollection, item, listPosition, query, totalResults]);

  return (
    <li className="c-nvsOverlay__opt">
      <a
        className="c-nvsOverlay__opt-link u-block u-t-body"
        href={item?.formatted_url}
        title={item?.name}
        onClick={handleClickKlevuResult}
      >
        <TaxonMatch
          itemSrc={item?.image}
          itemName={item?.name}
          itemHighlight={children}
          itemCount={item?.productCount}
          itemPrice={item?.price}
          itemSalePrice={item?.salePrice}
        />
      </a>
    </li>
  );
}

function PopularSuggestion({
  query,
  item = {},
  listPosition,
  totalResults = 0,
  children = null
}) {
  const handleClickPopularResult = useCallback(
    () =>
      trackSearchSuggestAction(ga4Events?.click_search_suggest, {
        ...trackingItemData(query, item),
        total: totalResults,
        listPosition,
        trigger: 'popular'
      }),
    [item, listPosition, query, totalResults]
  );

  return (
    <li className="c-nvsOverlay__pill">
      <a
        className="c-nvsOverlay__pill-link u-inline-block u-t-body u-t-nolined is-still"
        href={item?.formatted_url}
        title={item?.name}
        onClick={handleClickPopularResult}
      >
        {children}
      </a>
    </li>
  );
}

function RecentSearch({
  query = '',
  item = {},
  listPosition,
  children = null
}) {
  const handleClickRecentSearchResult = useCallback(
    () =>
      trackSearchSuggestAction(ga4Events?.click_search_suggest, {
        ...trackingItemData(query, item),
        listPosition,
        trigger: 'recent_search'
      }),
    [item, listPosition, query]
  );

  return (
    <li className="c-nvsOverlay__pill">
      <a
        className="c-nvsOverlay__pill-link u-inline-block u-t-body u-t-nolined is-still"
        href={item?.formatted_url}
        title={item.query}
        onClick={handleClickRecentSearchResult}
      >
        {children}
      </a>
    </li>
  );
}

export {
  OverlayBody,
  SubSection,
  NoMatches,
  KlevuSuggestion,
  PopularSuggestion,
  RecentSearch
};
