import { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { debounce } from 'lodash';

import atoms from '~/containers/shared/states/atoms';

export default function useViewportSize(delay = 200) {
  const isClient = useRecoilValue(atoms.isClient);
  const [width, setWidth] = useRecoilState(atoms.viewportWidth);
  const [height, setHeight] = useRecoilState(atoms.viewportHeight);

  const setSize = debounce(() => {
    setWidth(document.documentElement.clientWidth);
    setHeight(document.documentElement.clientHeight);
  }, delay);

  useEffect(() => {
    if (isClient) {
      setWidth(document.documentElement.clientWidth);
      setHeight(document.documentElement.clientHeight);
    }
  }, [isClient, setHeight, setWidth]);

  useEffect(() => {
    if (isClient) {
      window.addEventListener('resize', setSize);
      window.addEventListener('orientationchange', setSize);
    }

    return () => {
      if (isClient) {
        window.removeEventListener('resize', setSize);
        window.removeEventListener('orientationchange', setSize);
      }
    };
  }, [isClient, setSize, delay]);

  return { width, height };
}
