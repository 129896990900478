import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { isEmpty, isFunction, debounce } from 'lodash';

import atoms from '~/containers/shared/states/atoms';
import './OverflowRow.scss';

const LAZY_UPDATE_INTERVAL = 10;
const LAZY_SCROLL_DELAY = 2000;
const SCROLL_PERCENT = 0.8;

export default function OverflowRow({
  blockName = '',
  wrapperTag = 'div',
  listRole = undefined,
  listRef = {},
  overflowTolerance = 0,
  overflowDependencies = [],
  hasNavArrows = false,
  navArrowModifier = 'ic-xbld',
  navEndToEnd = true,
  children = null
}) {
  const hoverRef = useRef();
  const [rowBegin, setRowBegin] = useState(true);
  const [rowEnd, setRowEnd] = useState(true);

  const viewportWidth = useRecoilValue(atoms.viewportWidth);

  const TagType = useMemo(() => wrapperTag, [wrapperTag]);

  const arrowClasses = useMemo(
    () =>
      [navArrowModifier, !!blockName && `${blockName}__pt`]
        .filter((n) => !!n)
        .join(' '),
    [blockName, navArrowModifier]
  );

  const toggleHover = useCallback(
    (cb = null) =>
      () => {
        clearTimeout(hoverRef?.current);
        if (navEndToEnd && isFunction(cb))
          hoverRef.current = setTimeout(cb, LAZY_SCROLL_DELAY);
      },
    [navEndToEnd]
  );

  const scrollLeft = useCallback(() => {
    if (listRef?.current) {
      if (navEndToEnd) {
        // eslint-disable-next-line no-param-reassign
        listRef.current.parentNode.scrollLeft = 0;
      } else {
        const { parentNode } = listRef.current;
        parentNode.scrollLeft -= parentNode.clientWidth * SCROLL_PERCENT;
      }
    }
  }, [listRef, navEndToEnd]);

  const scrollRight = useCallback(() => {
    if (listRef?.current) {
      const { parentNode } = listRef.current;

      if (navEndToEnd) {
        parentNode.scrollLeft += listRef.current.clientWidth;
      } else {
        parentNode.scrollLeft += parentNode.clientWidth * SCROLL_PERCENT;
      }
    }
  }, [listRef, navEndToEnd]);

  const recheckOverflow = useCallback(() => {
    if (listRef?.current) {
      const {
        scrollWidth: listWidth,
        parentNode: { clientWidth: parentWidth, scrollLeft: scrollPos } = {}
      } = listRef.current;

      const standardLeft = overflowTolerance;
      const standardRight = listWidth - parentWidth - overflowTolerance;

      setRowBegin(scrollPos <= standardLeft);
      setRowEnd(scrollPos >= standardRight);
    }
  }, [listRef, overflowTolerance]);

  const lazyUpdate = debounce(recheckOverflow, LAZY_UPDATE_INTERVAL);

  const dependencies = useMemo(
    () => [
      lazyUpdate,
      viewportWidth,
      rowBegin,
      rowEnd,
      ...overflowDependencies
    ],
    [rowBegin, rowEnd, lazyUpdate, overflowDependencies, viewportWidth]
  );

  useEffect(() => {
    lazyUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  if (isEmpty(children)) return null;

  return (
    <TagType
      className={classNames(`c-ovRow ${blockName}`, {
        'is-bgn': rowBegin,
        'is-end': rowEnd
      })}
    >
      <div
        className={`c-ovRow__wrapper ${blockName}__wrapper`}
        onScroll={lazyUpdate}
      >
        <ul
          className={`c-ovRow__line ${blockName}__line u-minied-list u-t-nowrap`}
          ref={listRef}
          role={listRole}
        >
          {children}
        </ul>
      </div>
      <div className={`c-ovRow__fade ${blockName}__fade is-left`} />
      <div className={`c-ovRow__fade ${blockName}__fade is-right`} />

      {hasNavArrows && (
        <>
          <span
            role="button"
            tabIndex={-1}
            className={`c-ovRow__more ${blockName}__more u-p-pointer is-left`}
            onMouseEnter={toggleHover(scrollLeft)}
            onMouseLeave={toggleHover()}
            onClick={scrollLeft}
          >
            <span
              className={`c-ovRow__pt ic-bef ic-site-arrow ic-dir-left ${arrowClasses}`}
              aria-hidden
            />
          </span>
          <span
            role="button"
            tabIndex={-1}
            className={`c-ovRow__more ${blockName}__more u-p-pointer is-right`}
            onMouseEnter={toggleHover(scrollRight)}
            onMouseLeave={toggleHover()}
            onClick={scrollRight}
          >
            <span
              className={`c-ovRow__pt ic-bef ic-site-arrow ic-dir-right ${arrowClasses}`}
              aria-hidden
            />
          </span>
        </>
      )}
    </TagType>
  );
}
