import { useState, useCallback, useEffect } from 'react';
import { isEmpty, isFunction, isEqual, debounce, noop } from 'lodash';

const RESIZE_EVENT = 'resize';
const SCROLL_EVENT = 'scroll';
const LISTEN_DELAY = 500;

export default function useNodeHeight({
  ref,
  defaultHeight,
  setter = noop,
  dependencies = []
}) {
  const [nodeHeight, setNodeHeight] = useState(defaultHeight);

  const updateHeight = useCallback(() => {
    const bounds = isEmpty(ref?.current)
      ? { height: defaultHeight }
      : ref.current.getBoundingClientRect();
    const newHeight = bounds?.height;

    setNodeHeight((prev) => (isEqual(newHeight, prev) ? prev : newHeight));

    if (isFunction(globalThis?.HV?.Components?.HeaderHeight?.update)) {
      globalThis?.HV?.Components?.HeaderHeight?.update(newHeight);
    }
  }, [defaultHeight, ref]);

  const lazyUpdate = debounce(updateHeight, LISTEN_DELAY);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateHeight, dependencies);

  useEffect(() => {
    window.addEventListener(RESIZE_EVENT, lazyUpdate);
    window.addEventListener(SCROLL_EVENT, lazyUpdate);
    lazyUpdate();

    return () => {
      window.removeEventListener(RESIZE_EVENT, lazyUpdate);
      window.removeEventListener(SCROLL_EVENT, lazyUpdate);
    };
  }, [lazyUpdate]);

  useEffect(() => setter(nodeHeight), [nodeHeight, setter]);

  return nodeHeight;
}
