import { matchPath } from 'react-router-dom';
import { isEmpty, pickBy } from 'lodash';

import {
  // EDITORIAL_INDEX_NAME,
  // EDITORIAL_INDEX_PATH,
  // HOUSEWARM_INDEX_NAME,
  // PICTURES_INDEX_NAME,
  // CONTESTS_INDEX_NAME,
  // CONTESTS_INDEX_PATH,
  BLOG_INDEX_NAME,
  BLOG_INDEX_PATH,
  ARTICLE_INDEX_PATH,
  HOUSEWARM_INDEX_PATH,
  PICTURES_INDEX_ITEM,
  HOUSEWARM_INDEX_ITEM,
  PICTURES_INDEX_PATH,
  PICTURES_NEW_PATH,
  HASHTAG_INDEX_PATH,
  OFFER_INDEX_PATH
} from '~/containers/Blog/constants';
import {
  ACCOUNT_PATHS,
  USER_PATHS,
  USER_SUB
} from '~/containers/Account/constants';
import { SEARCH_ROUTE } from '~/containers/SearchResult/constants';

const housewarmType = 'housewarming';
// const editorialType = 'decor-guide';

export const CG1_COLLECTION = 'collection';
export const CG1_PRODUCT = 'product';
export const CG1_SEARCH = 'search';
export const CG1_BLOG = 'content';

export const BLOG_PAGEVIEWS_MAP = {
  [BLOG_INDEX_PATH]: {
    title: BLOG_INDEX_NAME,
    cg4: 'post-index',
    scan: false,
    tidy: false
  },
  // [EDITORIAL_INDEX_PATH]: {
  //   title: EDITORIAL_INDEX_NAME,
  //   cg4: `${editorialType}-index`,
  //   scan: false,
  //   tidy: false
  // },
  // [`${EDITORIAL_INDEX_PATH}/:slug`]: {
  //   title: `${EDITORIAL_INDEX_NAME} - {topic}`,
  //   cg4: `${editorialType}-{topic}`,
  //   scan: true,
  //   tidy: true
  // },
  // [HOUSEWARM_INDEX_PATH]: {
  //   title: HOUSEWARM_INDEX_NAME,
  //   cg4: `${housewarmType}-index`,
  //   scan: false,
  //   tidy: false
  // },
  // [`${HOUSEWARM_INDEX_PATH}/new`]: {
  //   title: `New ${HOUSEWARM_INDEX_ITEM}`,
  //   cg4: `${housewarmType}-new`,
  //   scan: false,
  //   tidy: false
  // },
  [`${HOUSEWARM_INDEX_PATH}/:slug/edit`]: {
    title: `Edit ${HOUSEWARM_INDEX_ITEM}`,
    cg4: `${housewarmType}-edit`,
    scan: true,
    tidy: false
  },
  [`${ARTICLE_INDEX_PATH}/:slug`]: {
    title: '{article_title}',
    cg4: '{article_type}-detail',
    scan: true,
    tidy: true
  },
  // [PICTURES_INDEX_PATH]: {
  //   title: PICTURES_INDEX_NAME,
  //   cg4: 'post-index',
  //   scan: false,
  //   tidy: false
  // },
  [PICTURES_NEW_PATH]: {
    title: `Create A ${PICTURES_INDEX_ITEM}`,
    cg4: 'post-new',
    scan: false,
    tidy: false
  },
  [`${PICTURES_INDEX_PATH}/posts/:slug`]: {
    title: '{post_title}',
    cg4: 'post-detail',
    scan: true,
    tidy: true
  },
  [`${PICTURES_INDEX_PATH}/:slug/edit`]: {
    title: `Edit ${PICTURES_INDEX_ITEM}`,
    cg4: 'post-edit',
    scan: true,
    tidy: false
  },
  [`${HASHTAG_INDEX_PATH}/:slug`]: {
    title: `#{hashtag} ${PICTURES_INDEX_ITEM}s`,
    cg4: 'post-hashtag',
    scan: true,
    tidy: true
  },
  [`${OFFER_INDEX_PATH}/:slug`]: {
    title: 'View product on Communa App',
    cg4: 'offer-detail',
    scan: true,
    tidy: true
  }
  // [CONTESTS_INDEX_PATH]: {
  //   title: CONTESTS_INDEX_NAME,
  //   cg4: 'contest-index',
  //   scan: false,
  //   tidy: false
  // },
  // [`${CONTESTS_INDEX_PATH}/:slug`]: {
  //   title: '{contest_title}',
  //   cg4: 'contest-detail',
  //   scan: true,
  //   tidy: true
  // }
};

export const USER_PAGEVIEWS_MAP = {
  [`${USER_SUB}/${USER_PATHS.home.route}`]: {
    title: `${USER_PATHS.home.title}`,
    cg1: 'profile',
    scan: true
  },
  [`${USER_SUB}/${USER_PATHS.pictures.route}`]: {
    title: `${USER_PATHS.pictures.title}`,
    cg1: 'profile',
    scan: true
  },
  [`${USER_SUB}/${USER_PATHS.housewarm.route}`]: {
    title: `${USER_PATHS.housewarm.title}`,
    cg1: 'profile',
    scan: true
  },
  [`${USER_SUB}/${USER_PATHS.saves.route}`]: {
    title: `${USER_PATHS.saves.title}`,
    cg1: 'profile',
    scan: true
  },
  [`${USER_SUB}/${USER_PATHS.wishlist.route}`]: {
    title: `${USER_PATHS.wishlist.title}`,
    cg1: 'profile',
    scan: true
  },
  [`${USER_SUB}/${USER_PATHS.likes.route}`]: {
    title: `${USER_PATHS.likes.title}`,
    cg1: 'profile',
    scan: true
  },
  [`${USER_SUB}/${USER_PATHS.follower.route}`]: {
    title: `${USER_PATHS.follower.title}`,
    cg1: 'profile',
    scan: true
  },
  [`${USER_SUB}/${USER_PATHS.followee.route}`]: {
    title: `${USER_PATHS.followee.title}`,
    cg1: 'profile',
    scan: true
  }
};

export const ACCOUNT_PAGEVIEWS_MAP = {
  [ACCOUNT_PATHS.shopping.path]: {
    title: `${ACCOUNT_PATHS.shopping.title}`,
    cg1: 'account',
    scan: false
  },
  [ACCOUNT_PATHS.orders.path]: {
    title: `${ACCOUNT_PATHS.orders.title}`,
    cg1: 'order',
    scan: false
  },
  [`${ACCOUNT_PATHS.orderDetail.path}/:id`]: {
    title: `${ACCOUNT_PATHS.orderDetail.title}`,
    cg1: 'order',
    scan: true
  },
  [`${ACCOUNT_PATHS.orderTracking.path}/:order_slug_id`]: {
    title: `${ACCOUNT_PATHS.orderTracking.title}`,
    cg1: 'order',
    scan: true
  },
  [ACCOUNT_PATHS.credits.path]: {
    title: `${ACCOUNT_PATHS.credits.title}`,
    cg1: 'account',
    scan: false
  },
  [ACCOUNT_PATHS.rewards.path]: {
    title: `${ACCOUNT_PATHS.rewards.title}`,
    cg1: 'account',
    scan: false
  },
  [ACCOUNT_PATHS.particulars.path]: {
    title: `${ACCOUNT_PATHS.particulars.title}`,
    cg1: 'account',
    scan: false
  },
  [ACCOUNT_PATHS.referrals.path]: {
    title: `${ACCOUNT_PATHS.referrals.title}`,
    cg1: 'account',
    scan: false
  },
  [ACCOUNT_PATHS.security.path]: {
    title: `${ACCOUNT_PATHS.security.title}`,
    cg1: 'account',
    scan: false
  },
  [ACCOUNT_PATHS.notifications.path]: {
    title: `${ACCOUNT_PATHS.notifications.title}`,
    cg1: 'account',
    scan: false
  },
  [ACCOUNT_PATHS.inbox.path]: {
    title: `${ACCOUNT_PATHS.inbox.title}`,
    cg1: 'account',
    scan: false
  }
};

export const detectPage = (pathname) => {
  let page = CG1_COLLECTION;
  if (pathname.includes('/products/')) page = CG1_PRODUCT;
  if (pathname.includes(SEARCH_ROUTE)) page = CG1_SEARCH;

  return page;
};

export const pagePattern = (pathname, mapping) =>
  Object.keys(pickBy(mapping, (obj) => obj?.scan))
    .map((pattern) =>
      matchPath(
        {
          path: pattern,
          exact: true,
          strict: false
        },
        pathname
      )
    )
    .filter((res) => !isEmpty(res));
