import React, { useState, useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { FORGOT_PASSWORD_API } from '~/containers/ModalsManager/constants';
import modalAtoms from '~/containers/ModalsManager/states/atoms';
import HvModal from '~/components/shared/HvModal';
import Spinner from '~/components/shared/Spinner';
import { Greeting } from '~/components/ModalAuth/AuthGreetings';
import { submit } from '~/utils/requestAPI';
import './PasswordReset.scss';

const EMAIL_FIELD_KEY = 'forgot_email';

export default function PasswordResetModal() {
  const [isInvalid, setIsInvalid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const setShowAuthModal = useSetRecoilState(modalAtoms.showRegisterModal);
  const [isOpen, setIsOpen] = useRecoilState(modalAtoms.showResetPasswordModal);
  const [errorMessage, setErrorMessage] = useRecoilState(
    modalAtoms.authErrorMessage
  );

  const handleDismissModal = useCallback(() => {
    setIsOpen(false);
    setShowAuthModal(true);
  }, [setIsOpen, setShowAuthModal]);

  const handleClose = useCallback(() => {
    setEmail('');
    handleDismissModal();
  }, [handleDismissModal]);

  const handleCancel = useCallback(() => {
    setIsSubmitting(false);
    handleDismissModal();
  }, [handleDismissModal]);

  const handleEmailChange = useCallback(
    (event) => {
      setErrorMessage('');
      setIsInvalid(false);
      setEmail(event?.target?.value);
    },
    [setErrorMessage]
  );

  const handleClickSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const checker = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      const invalidEmail = !checker.test(email);
      setIsInvalid(invalidEmail);
      if (invalidEmail) {
        setErrorMessage(
          isEmpty(email)
            ? 'Please enter an email address.'
            : 'Please enter a valid email address.'
        );
        return;
      }

      const { data } = await submit(FORGOT_PASSWORD_API, {
        'customer[email]': email
      });
      if (data) {
        const { success } = data;
        if (success) {
          setIsSuccess(true);
        }
      }
    } catch (error) {
      setErrorMessage(error);
      HV.HipvanLogger.notify(error);
    } finally {
      setIsSubmitting(false);
    }
  }, [email, setErrorMessage]);

  return (
    <HvModal
      onClose={handleDismissModal}
      isOpen={isOpen}
      blockName="c-resetPwd"
      contentLabel="Forgot Password Modal"
    >
      {isSuccess ? (
        <>
          <Greeting blockName="c-resetPwd" title="Link Sent!">
            <p className="c-resetPwd__p">
              {'If an account exists for '}
              <span className="c-resetPwd__email u-inline-block u-t-large u-t-primary u-t-bold">
                {email}
              </span>
              , you will get an email with link to reset your password.
            </p>
            <p className="c-resetPwd__p">
              In case it doesn&apos;t arrive, be sure to check your spam folder.
            </p>
          </Greeting>
          <div className="c-resetPwd__final">
            <button
              name="button"
              type="submit"
              className="c-resetPwd__confirm o-btn o-fd o-fd-primary o-fd--block"
              onClick={handleClose}
            >
              Ok, Got It
            </button>
          </div>
        </>
      ) : (
        <>
          <Greeting blockName="c-resetPwd" title="Forgot your password?">
            Enter the email address associated with your account, and we&apos;ll
            email you a link to reset your password.
          </Greeting>
          <div className="c-resetPwd__form">
            <div className="c-resetPwd__field">
              <input
                type="email"
                name="customer[email]"
                id={EMAIL_FIELD_KEY}
                className={classNames(
                  'c-resetPwd__input o-input o-fd o-fd-greymid',
                  { 'is-error': !isEmpty(errorMessage) }
                )}
                placeholder="Email"
                autoComplete="username"
                required
                onChange={handleEmailChange}
              />
              {!isEmpty(errorMessage) && (
                <div
                  className="c-resetPwd__error u-t-smaller u-t-error"
                  htmlFor={EMAIL_FIELD_KEY}
                >
                  {isInvalid ? (
                    errorMessage
                  ) : (
                    <>
                      We&apos;re unable to send your password reset link. Please
                      check your email and try again, or{' '}
                      <a
                        className="is-still u-t-lined"
                        href="mailto:wecare@hipvan.com"
                      >
                        contact us
                      </a>{' '}
                      <span className="u-t-nowrap">for help.</span>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="c-resetPwd__actions">
              <button
                type="submit"
                className="c-resetPwd__cta o-btn o-fd o-fd-primary o-fd--block"
                onClick={handleClickSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className="c-resetPwd__loading">
                    <Spinner preset="white" />
                  </div>
                ) : (
                  'Send Reset Link'
                )}
              </button>
              <div className="c-resetPwd__cancel u-t-greymid">
                <span
                  role="button"
                  tabIndex={-1}
                  className="c-resetPwd__cancel-txt u-t-small u-p-pointer"
                  onClick={handleCancel}
                >
                  Cancel
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </HvModal>
  );
}
