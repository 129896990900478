import axios from 'axios';
import queryStringForAll from 'query-string-for-all';

import railsVariables from '~/apps/railsVariables.js.erb';

const instance = axios.create({
  baseURL: railsVariables.KLEVU_ANALYTIC_URL
});

const sendGet = async (path, queries) => {
  try {
    const urlWithQueries = queryStringForAll.stringifyUrl({
      url: path,
      query: queries
    });
    return await instance.get(urlWithQueries);
  } catch (error) {
    return {
      message: `Oops! Something went wrong while setting up the request: ${error.message}`
    };
  }
};

export default sendGet;
