// eslint-disable-next-line import/no-extraneous-dependencies

import publicIp from 'public-ip';
import RailsVars from '~/apps/railsVariables.js.erb';
import sendKlevuAnalyticsRequest from '~/utils/requestKlevuAnalyticApi';

/**
 * Sends product tracking data to Klevu analytics.
 *
 * @param {Object} params - Tracking parameters.
 * @param {string} [params.keywords=''] - The search keywords.
 * @param {string} [params.type=''] - The type of tracking event.
 * @param {string} [params.id=''] - The product ID.
 * @param {string} [params.groupId=''] - The product group ID.
 * @param {string} [params.variantId=''] - The product variant ID.
 * @param {string} [params.name=''] - The product name.
 * @param {string} [params.url=''] - The product URL.
 * @returns {Promise<void>} - A promise that resolves when the tracking data has been sent.
 */
const trackKlevuSuggestionClick = async (params = {}) => {
  if (typeof window === 'undefined' || globalThis.isMobileApp) return;

  try {
    const ip = await publicIp.v4();
    const requestParams = {
      klevu_apiKey: RailsVars?.KLEVU_API_KEY ?? '',
      klevu_keywords: params.keywords || '',
      klevu_type: params.type || '',
      klevu_productId: params.id || '',
      klevu_productGroupId: params.groupId || '',
      klevu_productVariantId: params.variantId || '',
      klevu_productName: params.name || '',
      klevu_productUrl: params.url || '',
      klevu_shopperIP: ip
    };

    await sendKlevuAnalyticsRequest('/productTracking', requestParams);
  } catch (error) {
    globalThis.HV?.HipvanLogger?.notify(error, {
      context: {
        functionName: 'trackKlevuSuggestionClick',
        params
      }
    });
  }
};

export default trackKlevuSuggestionClick;
