const IMPRESSIONS_INTENT_DELAY = 1000;
const IMPRESSIONS_VIEW_THRESHOLD = 0.75;
const IMPRESSIONS_PUSH_INTERVAL = 1500;
const IMPRESSIONS_PRODUCT_PER_ROW = 3;

const impressLocationKeys = {
  clp_card: 'clp_card'
};

const hitLocations = {
  // NAVIGATION
  top_nav_base: 'top_nav_base',
  top_nav_account: 'top_nav_account',
  burger_nav_accordion: 'burger_nav_accordion',
  burger_nav_referral: 'burger_nav_referral',
  burger_nav_signin: 'burger_nav_signin',
  shop_sort_select_mobile: 'shop_sort_select_mobile',
  shop_sort_select_desktop: 'shop_sort_select_desktop',
  shop_filter_select_dismiss: 'shop_filter_select_dismiss',
  shop_filter_select_cta: 'shop_filter_select_cta',
  shop_filter_accordion_dismiss: 'shop_filter_accordion_dismiss',
  shop_filter_accordion_cta: 'shop_filter_accordion_cta',

  // ACCOUNT PROFILE, SHOPPING
  profile_user: 'profile_user',
  profile_follower_card: 'profile_follower_card',
  profile_following_card: 'profile_following_card',
  profile_like_card: 'profile_like_card',
  profile_save_card: 'profile_save_card',
  profile_post_card: 'profile_post_card',
  profile_housewarm_card: 'profile_housewarm_card',
  profile_suggest_card: 'profile_suggest_card',

  order_detail_invoice: 'order_detail_invoice',
  account_shopping: 'account_shopping',
  account_referral: 'account_referral',
  profile_wishlist: 'profile_wishlist',
  profile_wishlist_card: 'profile_wishlist_card',

  // HV COLLECTION
  hv_collection_list_card: 'hv_collection_list_card',
  hv_collection_related_pdts_lane: 'hv_collection_related_pdts_lane',
  hv_collection_related_cols_lane: 'hv_collection_related_cols_lane',
  hv_product_list_card: 'hv_product_list_card',
  hv_product_list_variant: 'hv_product_list_variant',

  // HV PRODUCT
  hv_product_heading: 'hv_product_heading',
  hv_product_pics_main: 'hv_product_pics_main',
  hv_product_pics_thumb: 'hv_product_pics_thumb',
  hv_product_review: 'hv_product_review',
  hv_product_share: 'hv_product_share',
  hv_product_community: 'hv_product_community',
  hv_product_story: 'hv_product_story',
  hv_product_recommend: 'hv_product_recommend',
  hv_product_cta: 'hv_product_cta',
  hv_product_sticky_cta: 'hv_product_sticky_cta',
  hv_product_quickview_cta: 'hv_product_quickview_cta',
  hv_product_swatch_cta: 'hv_product_swatch_cta',
  hv_product_hipblee_cta: 'hv_product_hipblee_cta',

  // FEATURED, POST:HOUSEWARM, POST:EDITORIAL, CONTESTS
  featured_hero_housewarm_card: 'featured_hero_housewarm_card',
  featured_housewarm_card: 'featured_housewarm_card',
  featured_editorial_card: 'featured_editorial_card',
  featured_contest_post_card: 'featured_contest_post_card',
  featured_post_card: 'featured_post_card',

  article_detail_header: 'article_detail_header',
  article_detail_author: 'article_detail_author',
  article_detail_aside_sticky: 'aside_sticky',
  article_detail_aside_sticky_like: 'aside_sticky_like',
  article_detail_aside_sticky_save: 'aside_sticky_save',
  article_detail_bottom_sticky: 'bottom_sticky',
  article_detail_bottom_sticky_like: 'bottom_sticky_like',
  article_detail_bottom_sticky_save: 'bottom_sticky_save',

  housewarm_submit: 'housewarm_submit',
  housewarm_list: 'housewarm_list',
  housewarm_list_card: 'housewarm_list_card',
  editorial_list_card: 'editorial_list_card',
  editorial_category: 'editorial_category',
  editorial_category_card: 'editorial_category_card',
  contest_list_card: 'contest_list_card',
  contest_entries: 'contest_entries',
  contest_entry_card: 'contest_entry_card',

  // POST:PICTURE, COMMENTS
  post_detail_aside_sticky_join: 'aside_sticky_join',
  post_detail_aside_sticky: 'aside_sticky',
  post_detail_aside_sticky_like: 'aside_sticky_like',
  post_detail_aside_sticky_save: 'aside_sticky_save',
  post_detail_bottom_sticky: 'bottom_sticky',
  post_detail_bottom_sticky_like: 'bottom_sticky_like',
  post_detail_bottom_sticky_save: 'bottom_sticky_save',
  post_detail_picture: 'post_detail_picture',
  post_submit: 'post_submit',
  post_list: 'post_list',
  post_list_card: 'post_list_card',
  post_list_callout: 'post_list_callout',
  post_list_success_modal: 'post_list_success_modal',
  post_recent: 'post_recent',
  post_recent_card: 'post_recent_card',
  post_related_card: 'post_related_card',
  post_author_other_card: 'post_author_other_card',

  post_filter: 'post_filter',
  post_filter_dismiss: 'post_filter_dismiss',
  post_filter_close: 'post_filter_close',
  post_filter_cta: 'post_filter_cta',

  section_comment: 'section_comment',
  comment_like: 'comment_like',
  comment_reply_root: 'comment_reply_root',
  comment_reply_sub: 'comment_reply_sub'
};

export {
  IMPRESSIONS_INTENT_DELAY,
  IMPRESSIONS_VIEW_THRESHOLD,
  IMPRESSIONS_PUSH_INTERVAL,
  IMPRESSIONS_PRODUCT_PER_ROW,
  impressLocationKeys,
  hitLocations
};
