// useContainerViewModel.js
import { useState, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import atoms from '~/containers/Header/states/atoms';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { SUCCESS_CODE, sendGet } from '~/utils/requestAPI';
import {
  POPULARITY_TAXON_ID_SET,
  POPULARITY_HANDLE_TAXON_KEY
} from '~/containers/Collection/constants';
import { sessionStorageHelper } from '~/utils/storageHelper';

function createPopularityHandleWithTaxonIdMap(navigationData) {
  if (!navigationData || !Array.isArray(navigationData)) {
    return {};
  }

  const handleTaxonIdMap = {};
  const processedHandles = new Set();

  function processCategory(category) {
    if (!category || typeof category !== 'object') {
      return;
    }
    if (
      processedHandles.has(category.handle) ||
      !category.handle ||
      !category.taxon_id
    ) {
      return;
    }

    const handle = String(category.handle);
    const taxonId = category.taxon_id;

    processedHandles.add(handle);
    if (POPULARITY_TAXON_ID_SET.has(taxonId)) {
      handleTaxonIdMap[handle] = taxonId;
    }
    if (Array.isArray(category.categories)) {
      category.categories.forEach(processCategory);
    }
  }

  try {
    navigationData.forEach(processCategory);
  } catch (error) {
    // error handling if needed
  }
  return handleTaxonIdMap;
}

export default function useContainerViewModel() {
  const [isLoading, setLoading] = useState(true);
  const setNavigation = useSetRecoilState(atoms.navigation);

  const initRequest = useCallback(async () => {
    try {
      const res = await sendGet('/navigation');
      const { status, data } = res || {};
      if (status === SUCCESS_CODE) {
        setNavigation(defaultForUndefinedOrNull(data, []));
        const popularityHandleWithTaxonIdMap =
          createPopularityHandleWithTaxonIdMap(data);
        sessionStorageHelper.setItem(
          POPULARITY_HANDLE_TAXON_KEY,
          popularityHandleWithTaxonIdMap
        );
      } else if (status > 0) {
        HV.HipvanLogger.notify('Navigation not found', { info: res });
      }
    } catch (error) {
      HV.HipvanLogger.notify(error);
    } finally {
      setLoading(false);
    }
  }, [setNavigation]);

  return {
    isLoading,
    initRequest
  };
}
