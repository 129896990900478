/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';
import { KlevuEvents, KlevuFetch, search as searchKlevu } from '@klevu/core';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import queryString from 'query-string-for-all';
import { isFunction, isEmpty, noop, first } from 'lodash';

import {
  DISPLAY_MATCHING_PRODUCTS,
  keyboardKeys,
  KLEVU_COLLECTIONS_SEARCH_API,
  RECENT_SEARCH_TERMS
} from '~/containers/Header/constants';
import { mediaQueryPreset } from '~/containers/shared/constants';
import { RESULT_PAGE_URL } from '~/containers/SearchResult/constants';
import atoms from '~/containers/Header/states/atoms';
import PlaceholderTicker from '~/components/shared/PlaceholderTicker';
import useDebounce from '~/hooks/shared/useDebounce';
import { localStorageHelper } from '~/utils/storageHelper';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { KLEVU_ABTEST_KEY } from '~/utils/Search/klevuHelper';
import { ga4Events } from '~/utils/analytics/gtm';
import { sendPost } from '~/utils/requestAPI';
import {
  trackSearchIntent,
  trackSearchSuggestAction
} from '~/containers/SearchResult/analytics';
import {
  removeRecentSearchById,
  saveRecentSearch
} from '~/components/Header/NavSearch/Overlay/components';
import './useShoppingSearch.scss';

const DELAY_SEARCH_INTENT = 2000;
const DELAY_INPUT_ONCHANGE = 200;

export default function useShoppingSearch({ trimmedQuery, suggestions = [] }) {
  const isDesktop = useMediaQuery(mediaQueryPreset.desktop);

  // --------------------
  // Search
  // --------------------

  const intentRef = useRef();
  const intentDataRef = useRef();
  const [preferredCollection, setPreferredCollection] = useState();
  const [klevuFetching, setKlevuFetching] = useState(false);
  const [matchProductFetching, setMatchProductFetching] = useState(false);
  const [totalMatchingProducts, setTotalMatchingProducts] = useState(0);
  const [matchingProducts, setMatchingProducts] = useState([]);
  const [lastResultsQuery, setLastResultsQuery] = useState(null);
  const [searchQuery, setSearchQuery] = useRecoilState(atoms.navSearchQuery);
  const setKlevuNavSearchResults = useSetRecoilState(
    atoms.klevuNavSearchResults
  );

  const searchResultPage = useMemo(
    () =>
      queryString.stringifyUrl({
        url: RESULT_PAGE_URL,
        query: { q: trimmedQuery }
      }),
    [trimmedQuery]
  );

  const matchingResultPage = useMemo(
    () => preferredCollection || searchResultPage,
    [preferredCollection, searchResultPage]
  );

  const abTestInfo = useCallback(
    () =>
      defaultForUndefinedOrNull(
        localStorageHelper.getItem(KLEVU_ABTEST_KEY)?.[0],
        {}
      ),
    []
  );

  const search = useCallback(
    async (query) => {
      try {
        if (isEmpty(query)) return;

        const { data = {} } = await sendPost(KLEVU_COLLECTIONS_SEARCH_API, {
          term: query
        });

        const {
          data: {
            preferred_collection: preferredCollectionData = null,
            queryResults = []
          }
        } = data;

        if (preferredCollectionData) {
          setPreferredCollection(`${preferredCollectionData}?opt=hv_match`);
        } else {
          setPreferredCollection(null);
        }

        const searchResult = first(queryResults);
        if (!searchResult) return;

        setLastResultsQuery(query);
        const {
          getSearchClickSendEvent,
          meta: { totalResultsFound, searchedTerm, typeOfSearch } = {},
          records = []
        } = searchResult;

        intentDataRef.current = {
          term: searchedTerm,
          totalResults: totalResultsFound,
          typeOfSearch,
          abTestId: abTestInfo()?.abTestId,
          abTestVariantId: abTestInfo()?.abTestVariantId
        };
        if (isFunction(getSearchClickSendEvent)) getSearchClickSendEvent();
        setKlevuNavSearchResults(records);
      } catch (error) {
        HV.HipvanLogger.notify(error);
      } finally {
        setKlevuFetching(false);
      }
    },
    [abTestInfo, setKlevuNavSearchResults, setPreferredCollection]
  );

  const getMatchingProducts = useCallback(
    async (query) => {
      try {
        setMatchProductFetching(true);
        const qid = 'search_results';

        const res = await KlevuFetch(
          searchKlevu(query, {
            id: qid,
            limit: DISPLAY_MATCHING_PRODUCTS,
            sort: 'RELEVANCE',
            searchPrefs: ['disableGrouping']
          })
        );
        const searchResult = res.queriesById(qid);
        if (!searchResult) return;

        setTotalMatchingProducts(searchResult.meta.totalResultsFound ?? 0);
        setMatchingProducts(
          searchResult?.records?.map((i) => ({
            ...i,
            formatted_url: `${i?.url}?opt=klevu`,
            search_type: 'klevu'
          })) ?? []
        );
      } catch (error) {
        HV.HipvanLogger.notify('Unable to load matching products', {
          info: error
        });
      } finally {
        setMatchProductFetching(false);
      }
    },
    [setMatchingProducts]
  );

  const lazyQuery = useDebounce(() => {
    search(trimmedQuery);
    getMatchingProducts(trimmedQuery);
  }, DELAY_INPUT_ONCHANGE);

  const trackIntent = useCallback((cb = noop) => {
    if (!isEmpty(intentDataRef.current)) {
      const totalResults = defaultForUndefinedOrNull(
        intentDataRef.current?.totalResults,
        0
      );

      trackSearchIntent({
        query: intentDataRef.current?.term
        // for now, there are always 0 product results
        // in autocomplete search
        // total: undefined
      });
      trackSearchSuggestAction(ga4Events?.view_search_suggest, {
        query: intentDataRef.current?.term,
        total: totalResults
      });
      KlevuEvents.search(intentDataRef.current);
      intentDataRef.current = null;
    }
    cb();
  }, []);

  // --------------------
  // Downshift Logic
  // --------------------

  const [searchFocus, setSearchFocus] = useRecoilState(atoms.searchFocus);

  const highlighted = useCallback(
    ({ id = '', name = '', isRecentSearch = false }) => {
      const safeTerm = trimmedQuery.replace(/[^\w\s]/gi, '');
      const parts = name.split(new RegExp(`(${safeTerm})`, 'gi'));

      const onRemove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        removeRecentSearchById(RECENT_SEARCH_TERMS, id);
      };

      return (
        <span>
          {parts.map((part, index) => (
            <span
              key={index}
              className={classNames({
                'u-t-bold': safeTerm.toLowerCase() === part.toLowerCase()
              })}
            >
              {part}
            </span>
          ))}
          {isRecentSearch && (
            <span
              role="button"
              tabIndex={-1}
              className="c-hlItem__icon u-t-greymid ic-bef ic-site-cross ic-xbld u-animate-all"
              onClick={onRemove}
            />
          )}
        </span>
      );
    },
    [trimmedQuery]
  );

  const inputOnChange = useCallback(
    (event) => {
      const query = defaultForUndefinedOrNull(event.target.value, '');
      setSearchQuery(query);
      setKlevuFetching(true);
      lazyQuery();
    },
    [lazyQuery, setSearchQuery]
  );

  const inputOnKeyDown = useCallback(
    (event) => {
      if (event.key === keyboardKeys.Enter && !isEmpty(trimmedQuery)) {
        clearTimeout(intentRef?.current);
        saveRecentSearch(RECENT_SEARCH_TERMS, {
          name: trimmedQuery,
          formatted_url: matchingResultPage
        });
        trackIntent(() => {
          globalThis.location.href =
            preferredCollection ||
            queryString.stringifyUrl({
              url: RESULT_PAGE_URL,
              query: {
                q: trimmedQuery
              }
            });
        });
        trackIntent(() => {
          globalThis.location.href = matchingResultPage;
        });
        setPreferredCollection(null);
      }
    },
    [trimmedQuery, matchingResultPage, trackIntent, preferredCollection]
  );

  const inputOnKeyUp = useCallback(() => {
    clearTimeout(intentRef?.current);
    intentRef.current = setTimeout(trackIntent, DELAY_SEARCH_INTENT);
  }, [trackIntent]);

  const inputOnFocus = useCallback(
    (cb = noop) => {
      if (trimmedQuery && trimmedQuery !== lastResultsQuery) lazyQuery();
      cb();
      setSearchFocus(true);
    },
    [trimmedQuery, lastResultsQuery, lazyQuery, setSearchFocus]
  );

  const inputOnBlur = useCallback(() => {
    setSearchFocus(false);
    setSearchQuery((prev = '') => prev.trim());
  }, [setSearchFocus, setSearchQuery]);

  // --------------------
  // Placeholders Ticker
  // --------------------

  const overlayRef = useRef();
  const [boxHeight, setBoxheight] = useState(null);
  const [movingList, setMovingList] = useState([]);
  const [showPrefix, setShowPrefix] = useState(false);

  const customPlaceholder = useRecoilValue(atoms.navCustomPlaceholder);

  const displayTerms = useMemo(
    () => suggestions.map((s) => s?.name),
    [suggestions]
  );

  const placeholderText = useMemo(
    () => (isEmpty(displayTerms) ? 'Find everything for your home' : null),
    [displayTerms]
  );

  useEffect(() => {
    if (!overlayRef?.current) return;

    const { height } = overlayRef.current.getBoundingClientRect();
    setBoxheight(height);
  }, [isDesktop]);

  useEffect(() => {
    const nextItem = movingList[1];
    if (!isEmpty(nextItem))
      setShowPrefix(!!suggestions.find((i) => i.name === nextItem)?.prefix);
  }, [movingList, suggestions]);

  const placeholderContent = useMemo(
    () => (
      <div ref={overlayRef} className="c-nvsTicker u-animate-all">
        <span
          className="c-nvsTicker__icon ic-bef ic-site-search ic-bold u-inline-block u-t-body is-still"
          aria-hidden
        />
        {isEmpty(customPlaceholder) ? (
          <>
            {showPrefix && (
              <span
                className={classNames('c-nvsTicker__prefix u-t-nowrap', {
                  hidden: !!searchQuery
                })}
              >
                Search for
              </span>
            )}
            <span
              className={`c-nvsTicker__cycle ${searchQuery ? 'hidden' : ''}`}
            >
              <PlaceholderTicker
                list={displayTerms}
                height={boxHeight}
                paused={searchFocus}
                searchText={searchQuery}
                setMovingList={setMovingList}
              />
            </span>
          </>
        ) : (
          <span
            className={classNames('c-nvsTicker__custom u-t-ellipsis', {
              hidden: !!searchQuery
            })}
          >
            {customPlaceholder}
          </span>
        )}
      </div>
    ),
    [
      boxHeight,
      customPlaceholder,
      displayTerms,
      searchFocus,
      searchQuery,
      showPrefix
    ]
  );

  return {
    placeholderContent,
    placeholderText,
    klevuFetching,
    matchProductFetching,
    totalMatchingProducts,
    matchingProducts,
    matchingResultPage,
    searchResultPage,
    highlighted,
    inputOnChange,
    inputOnKeyDown,
    inputOnKeyUp,
    inputOnFocus,
    inputOnBlur,
    trackIntent
  };
}
