/* eslint-disable */
(function() {
  if (window.CustomEvent) return false;
  function CustomEvent(event, args) {
    params = args || {
      bubbles: false,
      cancelable: false,
      detail: undefined
    };
    var _event = document.createEvent('CustomEvent');
    _event.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return _event;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();
