import React, { useEffect } from 'react';
import axios from 'axios';

import View from '~/containers/Header/view';
import useViewportSize from '~/hooks/shared/useViewportSize';
import useGetProfileInfo from '~/hooks/shared/useGetProfileInfo';
import useRecalcOnFontsLoad from '~/hooks/shared/useRecalcOnFontsLoad';
import useContainerViewModel from './viewModel/useContainerViewModel';

export default function Container({ modifierClasses = '' }) {
  const { isLoading, initRequest } = useContainerViewModel();

  useViewportSize();
  useRecalcOnFontsLoad();
  useGetProfileInfo({ isPrivate: true });

  useEffect(() => {
    const cancelSource = axios.CancelToken;
    const source = cancelSource.source();

    initRequest();

    return () => {
      source.cancel('cancel initRequest effect');
    };
  }, [initRequest]);

  return <View modifierClasses={modifierClasses} isLoading={isLoading} />;
}
