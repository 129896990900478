import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useGoogleLogin } from '@react-oauth/google';
import { isEmpty, noop } from 'lodash';
import axios from 'axios';

import {
  GOOGLE_LOGIN_API,
  GOOGLE_USER_INFO_URL
} from '~/containers/ModalsManager/constants';
import atoms from '~/containers/shared/states/atoms';
import modalAtoms from '~/containers/ModalsManager/states/atoms';
import { submit } from '~/utils/requestAPI';
import { trackAuthResponse, trackAuthLead } from '~/utils/analytics/gtm';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { SOURCE_DEVICE_CATEGORY } from '~/containers/shared/constants';

export default function useGoogleSignIn({ authProvider, handleClose = noop }) {
  const isClient = useRecoilValue(atoms.isClient);
  const setErrorMess = useSetRecoilState(modalAtoms.authErrorMessage);
  const authConfig = useRecoilValue(modalAtoms.authConfig);

  const onSuccess = useCallback(
    async (tokenResponse) => {
      try {
        const { pathname = '' } = window.location;
        const userInfoRes = await axios.get(GOOGLE_USER_INFO_URL, {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
        });
        const { data: userInfo = {} } = defaultForUndefinedOrNull(
          userInfoRes,
          {}
        );

        if (!isEmpty(userInfo)) {
          const { email, name, picture, sub } = userInfo;
          const body = {
            'google[id]': sub,
            'google[name]': name,
            'google[email]': email,
            'google[photo]': picture,
            source_path: isClient ? pathname : '',
            source_device_category: SOURCE_DEVICE_CATEGORY
          };
          const { data = {} } = await submit(GOOGLE_LOGIN_API, body);

          if (!isEmpty(data)) {
            const { success, message } = data;
            if (success) {
              trackAuthLead({
                mode: authConfig?.authMode,
                content: authConfig?.authContent,
                authProvider
              });
              trackAuthResponse({
                authProvider,
                response: 'success'
              });
              handleClose();
              if (isClient) window.location.reload();
            } else {
              trackAuthResponse({
                authProvider,
                response: `invalid - ${message}`
              });
              setErrorMess(message);
            }
          }
        } else {
          trackAuthResponse({
            authProvider,
            response: 'failure'
          });
          setErrorMess('Login failed');
        }
      } catch (error) {
        trackAuthResponse({
          authProvider,
          response: 'failure'
        });
        HV.HipvanLogger.notify(error);
      }
    },
    [
      authConfig?.authContent,
      authConfig?.authMode,
      authProvider,
      handleClose,
      isClient,
      setErrorMess
    ]
  );

  const onError = useCallback(
    (error) => {
      setErrorMess(error);
    },
    [setErrorMess]
  );

  const googleLogin = useGoogleLogin({
    onSuccess,
    onError
  });

  return googleLogin;
}
