/* eslint-disable consistent-return */

import isClientSide from './clientSide';
import isMobile from './mobile';

export default function isDesktop() {
  if (!isClientSide()) return;

  return !isMobile();
}
