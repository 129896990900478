import keyMirror from 'keymirror';
import { uid } from 'react-uid';

import RailsVars from '~/apps/railsVariables.js.erb';

const SearchConstants = keyMirror({
  CHANGE_SORT_ACTION: null,
  CHANGE_PRICE_FILTER: null,
  CHANGE_SHIPPING_DURATION_FILTER: null,
  CHANGE_OFFSET_ACTION: null,
  ADD_QUERY_TO_SEARCH_PARAMS_ACTION: null,
  UPDATE_DATA: null,
  GET_NEW_DATA: null,
  TMP_ADD_PRICE_FILTER: null,
  TMP_REMOVE_PRICE_FILTER: null,
  RESTORE_STATE_FROM_LOCAL: null
});

const initPriceRange = {
  ZERO_TO_FIVE_HUNDRED: { label: '$0 - $500', value: '0 - 500' },
  FIVE_HUNDRED_TO_THOUSAND: { label: '$501 - $1000', value: '501 - 1000' },
  THOUSAND_TO_THOUSAND_FIVE_HUNDRED: {
    label: '$1001 - $1500',
    value: '1001 - 1500'
  },
  THOUSAND_FIVE_HUNDRED_TO_TWO_THOUSAND: {
    label: '$1501 - $2000',
    value: '1501 - 2000'
  },
  TWO_THOUSAND_AND_ABOVE: { label: '$2001 and Above', value: '2001-*' }
};

const initPriceFilters = [
  {
    id: uid(initPriceRange.ZERO_TO_FIVE_HUNDRED.value),
    checked: false,
    label: initPriceRange.ZERO_TO_FIVE_HUNDRED.label,
    value: initPriceRange.ZERO_TO_FIVE_HUNDRED.value
  },
  {
    id: uid(initPriceRange.FIVE_HUNDRED_TO_THOUSAND.value),
    checked: false,
    label: initPriceRange.FIVE_HUNDRED_TO_THOUSAND.label,
    value: initPriceRange.FIVE_HUNDRED_TO_THOUSAND.value
  },
  {
    id: uid(initPriceRange.THOUSAND_TO_THOUSAND_FIVE_HUNDRED.value),
    checked: false,
    label: initPriceRange.THOUSAND_TO_THOUSAND_FIVE_HUNDRED.label,
    value: initPriceRange.THOUSAND_TO_THOUSAND_FIVE_HUNDRED.value
  },
  {
    id: uid(initPriceRange.THOUSAND_FIVE_HUNDRED_TO_TWO_THOUSAND.value),
    checked: false,
    label: initPriceRange.THOUSAND_FIVE_HUNDRED_TO_TWO_THOUSAND.label,
    value: initPriceRange.THOUSAND_FIVE_HUNDRED_TO_TWO_THOUSAND.value
  },
  {
    id: uid(initPriceRange.TWO_THOUSAND_AND_ABOVE.value),
    checked: false,
    label: initPriceRange.TWO_THOUSAND_AND_ABOVE.label,
    value: initPriceRange.TWO_THOUSAND_AND_ABOVE.value
  }
];

const SEARCH_PAGE_TITLE = 'Search';
const SEARCH_ROUTE = '/results.html';
const SEARCH_DEFAULT_PER_PAGE = 33;

const SEARCH_OVERLAY_PAGE_TITLE = 'Search Start';
const SEARCH_OVERLAY_ROUTE = '/search_intent';

const RESULT_PAGE_URL = `${RailsVars.BASE_LAC_LONG_QUAN_URL}${SEARCH_ROUTE}`;

const INIT_MIN_PRICE = '0';
const INIT_MAX_PRICE = '9999';
const SEARCH_DEFAULT_SORT_BY = { field: 'RELEVANCE' };
const SEARCH_DEFAULT_SORT_KEY = 'SEARCH_DEFAULT_SORT_KEY';

const SORT_OPTIONS = [
  {
    label: 'Relevance',
    value: 'RELEVANCE'
  },
  {
    label: 'Popularity',
    value: 'ADVANCED_SORT',
    advancedSort: { key: 'popularity', order: 'ASC', type: 'FIELD' }
  },
  {
    label: 'Price: Low to High',
    labelMobile: 'Lowest Price',
    value: 'PRICE_ASC'
  },
  {
    label: 'Price: High to Low',
    labelMobile: 'Highest Price',
    value: 'PRICE_DESC'
  },
  {
    label: 'Delivery Time',
    value: 'ADVANCED_SORT+1',
    advancedSort: { key: 'earliestDelivery', order: 'ASC', type: 'FIELD' }
  }
];

const SUGGEST_SEARCH_TYPES = {
  klevu: 'klevu',
  hv_link: 'hv_link',
  hv_term: 'hv_term'
};

const searchPathName = (query) => `${SEARCH_ROUTE}?q=${query}`;

export {
  SearchConstants,
  initPriceFilters,
  SEARCH_PAGE_TITLE,
  SEARCH_ROUTE,
  SEARCH_DEFAULT_PER_PAGE,
  SEARCH_OVERLAY_PAGE_TITLE,
  SEARCH_OVERLAY_ROUTE,
  RESULT_PAGE_URL,
  INIT_MIN_PRICE,
  INIT_MAX_PRICE,
  SEARCH_DEFAULT_SORT_BY,
  SEARCH_DEFAULT_SORT_KEY,
  SORT_OPTIONS,
  SUGGEST_SEARCH_TYPES,
  searchPathName
};
