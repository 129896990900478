/* eslint-disable react/no-danger, react/jsx-props-no-spreading */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { delay, isEmpty, noop } from 'lodash';

import { GLOBAL_BASE_PX } from '~/utils/modularScale';
import './PlaceholderTicker.scss';

const DEFAULT_ROW_HEIGHT = GLOBAL_BASE_PX * 1.5;
const DEFAULT_INTERVAL = 2500;
const DEFAULT_ANIMATION = 500;

export default function PlaceholderTicker({
  list = [],
  duration = DEFAULT_INTERVAL,
  speed = DEFAULT_ANIMATION,
  height = DEFAULT_ROW_HEIGHT,
  paused = false,
  searchText = '',
  setMovingList = noop
}) {
  const sliderRef = useRef(null);
  const [items, setItems] = useState(list);
  const [ready, setReady] = useState(false);
  const [moving, setMoving] = useState(false);
  const intervalRef = useRef(null);

  const handleUpAnimation = useCallback(
    async (newList) => {
      if (sliderRef?.current) {
        const firstNode = sliderRef.current.children[0];
        setItems(newList);

        // First element will go up height px in speed ms
        firstNode.style.cssText = `
          margin: -${height}px 0 0 0;
          transition: all ${speed}ms ease;
        `;

        // Wait for speed ms and send first element to end of the list.
        // After that get first list element back to margin 0.
        await new Promise((resolve) => {
          delay(() => {
            newList.shift();
            setItems(newList);
            firstNode.style.cssText = 'margin: 0';
            resolve();
          }, speed);
        });
      }
    },
    [height, speed]
  );

  const moveUp = useCallback(async () => {
    if (moving) return;

    setMoving(true);

    const itemsCopy = items.slice(0);
    setMovingList(itemsCopy);
    if (itemsCopy[0]) itemsCopy.push(itemsCopy[0]);

    if (!isEmpty(itemsCopy)) await handleUpAnimation(itemsCopy);

    setMoving(false);
  }, [handleUpAnimation, items, moving, setMovingList]);

  const moveToNext = useCallback(() => {
    if (!paused) moveUp();
  }, [moveUp, paused]);

  useEffect(() => {
    setReady(false);

    if (sliderRef?.current) {
      setItems(list);
      setReady(true);
    }
  }, [list, ready]);

  useEffect(() => {
    if (!!ready || !isEmpty(items)) {
      clearInterval(intervalRef.current);
      if (globalThis.isClient) {
        intervalRef.current = setInterval(() => moveToNext(), duration);
      }
    }
  }, [duration, items, moveToNext, ready]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [intervalRef]);

  if (!isEmpty(searchText)) return null;

  return (
    <ul
      ref={sliderRef}
      className={`c-phTicker u-minied-list ${moving ? 'is-moving' : ''}`}
      style={{ height }}
    >
      {items.map((item, idx) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          className="c-phTicker__opt"
        >
          <div className="c-phTicker__label u-t-ellipsis">{item}</div>
        </li>
      ))}
    </ul>
  );
}
