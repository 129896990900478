import { atom } from 'recoil';

import { atomKeys } from '~/containers/ProductDetail/constants';

const mobileStickyFooterHeight = atom({
  key: atomKeys.MOBILE_STICKY_FOOTER_HEIGHT,
  default: 0
});

const mobileTabsMenuHeight = atom({
  key: atomKeys.MOBILE_TABS_MENU_HEIGHT,
  default: 0
});

const autoExpandProductInfo = atom({
  key: atomKeys.AUTO_EXPAND_PRODUCT_INFO,
  default: false
});

const autoExpandProductReviews = atom({
  key: atomKeys.AUTO_EXPAND_PRODUCT_REVIEWS,
  default: false
});

const flashPopup = atom({
  key: atomKeys.FLASH_POPUP,
  default: {
    show: false,
    message: '',
    displayTime: 1000
  }
});

export default {
  mobileStickyFooterHeight,
  mobileTabsMenuHeight,
  autoExpandProductInfo,
  autoExpandProductReviews,
  flashPopup
};
