import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { isEmpty, debounce, isEqual, noop } from 'lodash';

import atoms from '~/containers/shared/states/atoms';
import headerAtoms from '~/containers/Header/states/atoms';
import OverflowRow from '~/components/shared/OverflowRow';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { ga4Events } from '~/utils/analytics/gtm';
import {
  NAV_MODES,
  NAV_HIERARCHY,
  trackNavAction
} from '~/containers/Header/analytics';
import './NavRootsLane.scss';

const LISTEN_DELAY = 750;
const RESIZE_EVENT = 'resize';
const MATTRESS_TAXON_ID = 1200;
const COMMON_CLASSES = 'u-t-nolined u-t-nooutline u-animate-all is-still';

function Pill({
  node = {},
  activeId = undefined,
  setActiveId = noop,
  activeChild = undefined,
  setActiveChild = noop,
  handleClickActive = noop
}) {
  const handleClickOption = useCallback(() => {
    setActiveChild(null);
    setActiveId(node?.taxon_id);
    trackNavAction(ga4Events?.nav_click_link, {
      mode: NAV_MODES?.roots_slider,
      path: node?.relative_url,
      hierarchy: NAV_HIERARCHY?.root
    });
  }, [node?.relative_url, node?.taxon_id, setActiveChild, setActiveId]);

  const linkElement = useCallback(
    (isActive) => (
      <a
        className={classNames(`c-nvRoots__tab u-t-body ${COMMON_CLASSES}`, {
          'is-active': isActive
        })}
        data-nvid={node?.taxon_id}
        title={node?.name}
        href={node?.relative_url}
        onClick={handleClickOption}
      >
        {!!node?.icon_classname && (
          <span
            className={`c-nvRoots__icon ic-bef ${node?.icon_classname}`}
            aria-hidden
          />
        )}
        <span className="c-nvRoots__label">{node?.name}</span>
      </a>
    ),
    [
      handleClickOption,
      node?.icon_classname,
      node?.name,
      node?.relative_url,
      node?.taxon_id
    ]
  );

  if (node?.taxon_id === activeChild) return linkElement(true);

  if (node?.taxon_id === activeId)
    return (
      <div
        className={classNames(`c-nvRoots__tab ${COMMON_CLASSES}`, {
          'is-active': true
        })}
        data-nvid={node?.taxon_id}
        title={node?.name}
        role="button"
        tabIndex={-1}
        onClick={handleClickActive}
      >
        {!!node?.icon_classname && (
          <span
            className={`c-nvRoots__icon ic-bef ${node?.icon_classname}`}
            aria-hidden
          />
        )}
        <span className="c-nvRoots__label">{node?.name}</span>
      </div>
    );

  return linkElement(false);
}

export default function NavRoots({
  observing = [],
  currentPath = '',
  isCollectionPage = false,
  isMattressCMS = false
}) {
  const listRef = useRef(null);
  const [listPos, setListPos] = useState(0);
  const [activeOpt, setActiveOpt] = useState(null);
  const [activeSub, setActiveSub] = useState(null);
  const isClient = useRecoilValue(atoms.isClient);
  const navTree = useRecoilValue(headerAtoms.navigation);

  const pillRefs = useMemo(
    () =>
      navTree.reduce((acc, opt) => {
        acc[opt.taxon_id] = React.createRef();
        return acc;
      }, {}),
    [navTree]
  );

  const getHandle = useCallback((path = '') => path.split('/').pop(), []);

  const checkInitActive = useCallback(() => {
    const taxonMatch = navTree.find(
      (root) => getHandle(root?.relative_url) === getHandle(currentPath)
    );
    const taxonChildMatch = navTree.find((root) =>
      currentPath.includes(`${root?.relative_url}/`)
    );

    if (isMattressCMS) {
      setActiveOpt(MATTRESS_TAXON_ID);
      setActiveSub(null);
      return;
    }
    if (!isEmpty(taxonMatch)) {
      setActiveOpt(taxonMatch?.taxon_id);
      setActiveSub(null);
      return;
    }
    if (!isEmpty(taxonChildMatch)) {
      setActiveOpt(taxonChildMatch?.taxon_id);
      setActiveSub(taxonChildMatch?.taxon_id);
    }
  }, [currentPath, getHandle, isMattressCMS, navTree]);

  const updateScrollPos = useCallback(() => {
    const menuNode = listRef?.current;
    const activeNode = pillRefs?.[activeOpt]?.current;

    if (!isEmpty(menuNode) && !isEmpty(activeNode)) {
      const wrapperRect = menuNode.parentNode.getBoundingClientRect();
      const activeRect = activeNode.getBoundingClientRect();
      const latest =
        activeRect.left -
        (wrapperRect.left + wrapperRect.width / 2 - activeRect.width / 2);
      setListPos((prev) => (isEqual(prev, latest) ? prev : latest));
    }
  }, [activeOpt, pillRefs]);

  useEffect(() => {
    if (!isClient) return;
    if (isCollectionPage || isMattressCMS) checkInitActive();
  }, [checkInitActive, isClient, isCollectionPage, isMattressCMS]);

  useEffect(() => {
    const onResize = debounce(updateScrollPos, LISTEN_DELAY);

    window.addEventListener(RESIZE_EVENT, onResize);
    onResize();

    return () => {
      window.removeEventListener(RESIZE_EVENT, onResize);
    };
  }, [updateScrollPos, observing]);

  useEffect(() => {
    const listNode = listRef?.current;
    if (!isEmpty(listNode)) listNode.parentNode.scrollLeft += listPos;
  }, [listPos]);

  return (
    <OverflowRow
      blockName="c-nvRoots"
      listRef={listRef}
      hasNavArrows
      navEndToEnd={false}
    >
      {defaultForUndefinedOrNull(navTree, []).map((node) => (
        <li
          key={node?.id}
          ref={pillRefs?.[node?.taxon_id]}
          className="c-nvRoots__opt u-inline-block u-t-nowrap"
        >
          <Pill
            node={node}
            activeId={activeOpt}
            setActiveId={setActiveOpt}
            activeChild={activeSub}
            setActiveChild={setActiveSub}
            handleClickActive={updateScrollPos}
          />
        </li>
      ))}
    </OverflowRow>
  );
}
